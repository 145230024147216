export const RESIZE_ALGOS = {
  SL: "SL",
  AC_US: "AC_US"
  // Add more if you found some here
};

/***
 * Generate Amazon product image URL from input product image filename.
 * @param {string} image - Filename of the image.
 * @param {number} size - Size of the image in pixels (without the unit).
 * @param {string} resizeAlgo - Resize behavior of Amazon product image. Must set `size` value first to work. 
 *     Value can only be either `SL` or `AC_US`.
 *     Setting the value to `AC_US` will pad the image with white if the original image is **not** in 1:1 ratio.
 *     Setting the value  to `SL` will not pad the image to achieve 1:1 ratio.
 *     For example, you can take a look at the difference between the two images here:
 *         * https://images-na.ssl-images-amazon.com/images/I/41Y81gdCQfL._SL128_.jpg
 *         * https://images-na.ssl-images-amazon.com/images/I/41Y81gdCQfL._AC_US128_.jpg
 * 
 *     Defaults to `AC_US`.
 */
export function generateAmazonImageUrl(image, size, resizeAlgo = RESIZE_ALGOS.AC_US) {
  if (resizeAlgo && !(resizeAlgo in RESIZE_ALGOS)) {
    const availableResizeAlgos = Object.values(RESIZE_ALGOS).join(", ");
    throw new Error(`Specified resize algorithm not available, only choose one of the following: ${availableResizeAlgos}`);
  }

  if (size) { 
    image = image.replace(/\./, `._${resizeAlgo}${size}_.`)
  }

  return `https://images-na.ssl-images-amazon.com/images/I/${image}`
}
