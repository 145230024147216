import React from "../../../_snowpack/pkg/react.js";
import {StatusOfflineIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
const OfflinePage = ({handleReconnect}) => /* @__PURE__ */ React.createElement("main", {
  className: "min-h-screen flex flex-col justify-center items-center"
}, /* @__PURE__ */ React.createElement(StatusOfflineIcon, {
  className: "w-40 text-primary"
}), /* @__PURE__ */ React.createElement("h1", {
  className: "text-2xl mb-4 font-bold"
}, "You are currently offline."), /* @__PURE__ */ React.createElement("p", {
  className: "font-medium mb-4 text-gray-700"
}, "Please check your internet connection."), /* @__PURE__ */ React.createElement("button", {
  className: "flex font-medium items-center justify-center p-3 w-40 rounded-full border-2 bg-white border-primary text-primary",
  onClick: handleReconnect
}, "Reconnect"));
export default OfflinePage;
