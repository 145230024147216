import React from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import StarSFillIcon from "../../../_snowpack/pkg/remixicon-react/StarSFillIcon.js";
import NumberFormat from "../../../_snowpack/pkg/react-number-format.js";
import "./index.css";
const ScanResultsProducts = (props) => {
  const {product = {}} = props;
  const history = useHistory();
  const onClickProductDetail = () => {
    history.push(`/products/${product.asin}`);
  };
  const mainCategory = product?.categories?.slice(-1)[0];
  return /* @__PURE__ */ React.createElement("div", {
    onClick: onClickProductDetail,
    className: "bg-white w-full flex p-5 border-b product-list-item"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-1/5 flex justify-center items-center"
  }, /* @__PURE__ */ React.createElement("img", {
    src: `https://images-na.ssl-images-amazon.com/images/I/${product.image}`,
    width: "150"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "w-4/5 pl-4"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "cust-black-color text-sm text-justify"
  }, product.title), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", {
    className: "cust-light-gray-color text-xs mr-1.5"
  }, "ASIN: ", product.asin)), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", {
    className: "cust-black-color text-xs"
  }, "Price (NEW):", " ", /* @__PURE__ */ React.createElement("b", null, " ", /* @__PURE__ */ React.createElement(NumberFormat, {
    value: product.new.current,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }))))), /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "flex items-center cust-light-gray-color text-xs mr-1.5"
  }, /* @__PURE__ */ React.createElement(StarSFillIcon, {
    size: "1.2em",
    color: "#ECC713"
  }), " ", product.rating.current), mainCategory ? /* @__PURE__ */ React.createElement("span", {
    className: "cust-light-gray-color text-xs"
  }, "#", mainCategory.ranking, " | Top", " ", (mainCategory.rankingRate * 100).toFixed(), "% |", " ", mainCategory.name) : "")));
};
export default ScanResultsProducts;
