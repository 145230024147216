import React from "../../../_snowpack/pkg/react.js";
import ArrowUpLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowUpLineIcon.js";
import ArrowDownLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowDownLineIcon.js";
const ArrowPercent = (props) => {
  const {value} = props;
  if (!value) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  } else if ((value * 100).toFixed() === "0") {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  } else if (value > 0) {
    return /* @__PURE__ */ React.createElement("span", {
      className: "text-red-500 text-xxs"
    }, /* @__PURE__ */ React.createElement(ArrowDownLineIcon, {
      size: "0.75rem",
      className: "inline ml-1"
    }), (value * 100).toFixed(), "%");
  } else {
    return /* @__PURE__ */ React.createElement("span", {
      className: "text-green-500 text-xxs"
    }, /* @__PURE__ */ React.createElement(ArrowUpLineIcon, {
      size: "0.75rem",
      className: "inline ml-1"
    }), (value * 100).toFixed() * -1, "%");
  }
};
export default ArrowPercent;
