import React from "../../../_snowpack/pkg/react.js";
const ReplenDashboardLogo = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: "106",
  height: "63",
  viewBox: "0 0 106 63",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M3.55754 33.8412V31.5584H0V51.1693H3.55754V41.3565C3.92812 34.2858 9.62019 34.3451 9.62019 34.3451V30.8321C9.62019 30.8321 5.23255 30.5504 3.55754 33.8412Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.8116 48.3974C17.0614 48.3974 14.0226 45.3587 14.0226 41.6084C14.0226 37.8582 17.0614 34.8194 20.8116 34.8194C22.5459 34.8194 24.1172 35.4717 25.3178 36.5389L15.5494 41.9494L17.2689 45.0622L28.5493 38.8069L30.4022 37.7692L30.4466 37.7396C30.3873 37.6358 30.328 37.5469 30.2687 37.458C28.6678 33.7966 25.0362 31.2471 20.7968 31.2471C15.0899 31.2471 10.4503 35.8719 10.4503 41.5936C10.4503 47.3005 15.0751 51.9401 20.7968 51.9401C26.2517 51.9401 30.7134 47.7155 31.1137 42.3644H27.5413C27.1707 45.7589 24.295 48.3974 20.8116 48.3974Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M75.2124 48.3974C71.4622 48.3974 68.4234 45.3587 68.4234 41.6084C68.4234 37.8582 71.4622 34.8194 75.2124 34.8194C76.9467 34.8194 78.518 35.4717 79.7186 36.5389L69.9502 41.9494L71.6697 45.0622L82.9501 38.8069L84.803 37.7692L84.8474 37.7396C84.7881 37.6358 84.7289 37.5469 84.6696 37.458C83.0687 33.7966 79.437 31.2471 75.1976 31.2471C69.4907 31.2471 64.8511 35.8719 64.8511 41.5936C64.8511 47.3005 69.4759 51.9401 75.1976 51.9401C80.6525 51.9401 85.1143 47.7155 85.5145 42.3644H81.9421C81.5715 45.7589 78.6959 48.3974 75.2124 48.3974Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M44.084 31.1285C38.3771 31.1285 33.7374 35.7533 33.7374 41.4751V59.7519H37.295V49.2868V41.4899C37.295 37.7396 40.3337 34.7157 44.0691 34.7157C47.8194 34.7157 50.8433 37.7545 50.8433 41.4899C50.8433 45.2401 47.8046 48.264 44.0691 48.264C42.0087 48.264 40.1558 47.345 38.9107 45.8775V50.443C40.4226 51.3176 42.1866 51.8216 44.0691 51.8216C49.776 51.8216 54.4157 47.1968 54.4157 41.4751C54.4305 35.7533 49.8057 31.1285 44.084 31.1285Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M61.427 22.8127H57.8694V51.451H61.427V22.8127Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M97.5359 30.7283C92.8667 30.7283 89.072 34.523 89.072 39.1923V51.4361H92.6295V39.1923C92.6295 36.4796 94.8233 34.2858 97.5359 34.2858C100.249 34.2858 102.442 36.4796 102.442 39.1923V51.4361H106V39.1923C106 34.523 102.22 30.7283 97.5359 30.7283Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M54.2822 2.87568C63.6208 2.87568 71.4919 9.10138 73.9821 17.6395C72.9001 7.72284 64.4954 0 54.2822 0C44.0691 0 35.6644 7.70801 34.5823 17.6247C37.0726 9.10138 44.9585 2.87568 54.2822 2.87568Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M60.8045 8.28608C60.9082 8.13785 60.9824 7.70798 60.8045 7.57457C60.6266 7.44116 60.2412 7.64868 60.1226 7.79692L53.9414 15.5198C53.8673 15.5049 53.7931 15.5049 53.7042 15.5049C52.7407 15.5049 51.9551 16.2906 51.9551 17.2541C51.9551 18.2176 52.7407 19.0032 53.7042 19.0032C54.6677 19.0032 55.4533 18.2176 55.4533 17.2541C55.4533 17.0021 55.394 16.7649 55.3051 16.5425L60.8045 8.28608Z",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.7227 2.37183C14.7341 2.37183 9.81287 6.89287 9.17548 12.7184C10.643 7.72297 15.2529 4.07648 20.7227 4.07648C26.1924 4.07648 30.8024 7.72297 32.2699 12.7184C31.6473 6.89287 26.7112 2.37183 20.7227 2.37183Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.9747 12.3478C20.9303 12.3478 20.8858 12.3478 20.8561 12.3626L16.9873 7.53031C16.928 7.45619 16.6908 7.32278 16.5871 7.41172C16.4833 7.48584 16.5278 7.73783 16.5871 7.82677L20.026 13C19.9816 13.1186 19.9519 13.252 19.9519 13.4003C19.9519 13.9635 20.4114 14.4231 20.9747 14.4231C21.538 14.4231 21.9975 13.9635 21.9975 13.4003C21.9975 12.8073 21.538 12.3478 20.9747 12.3478Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M87.1895 4.06159C92.6593 4.06159 97.2692 7.70808 98.7367 12.7035C98.0993 6.8928 93.1781 2.35693 87.1895 2.35693C81.201 2.35693 76.2797 6.87798 75.6423 12.7035C77.1098 7.70808 81.7198 4.06159 87.1895 4.06159Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M94.0822 11.3398L87.8417 12.6294C87.6638 12.4515 87.4118 12.3478 87.1302 12.3478C86.5669 12.3478 86.1074 12.8073 86.1074 13.3706C86.1074 13.9338 86.5669 14.3933 87.1302 14.3933C87.6045 14.3933 88.0047 14.0672 88.1233 13.6374L94.2008 11.8141C94.3045 11.7845 94.4972 11.6214 94.4676 11.488C94.438 11.3694 94.186 11.325 94.0822 11.3398Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M87.2042 57.2617C85.7515 57.2617 84.5805 58.4327 84.5805 59.8854C84.5805 61.3381 85.7515 62.5091 87.2042 62.5091C88.6569 62.5091 89.8279 61.3381 89.8279 59.8854C89.8279 58.4327 88.642 57.2617 87.2042 57.2617ZM87.2042 61.6049C86.2555 61.6049 85.4699 60.8341 85.4699 59.8706C85.4699 58.9071 86.2407 58.1363 87.2042 58.1363C88.1529 58.1363 88.9385 58.9071 88.9385 59.8706C88.9385 60.8341 88.1529 61.6049 87.2042 61.6049Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M59.2331 59.8705C59.2331 60.8192 58.4622 61.6048 57.4987 61.6048C56.5501 61.6048 55.7644 60.834 55.7644 59.8705C55.7644 58.907 56.5352 58.1362 57.4987 58.1362C57.9434 58.1362 58.3437 58.2993 58.6401 58.5661V57.4988C58.2992 57.3358 57.899 57.232 57.4987 57.232C56.0461 57.232 54.8751 58.403 54.8751 59.8557C54.8751 61.3084 56.0461 62.4794 57.4987 62.4794C58.9514 62.4794 60.1224 61.3084 60.1224 59.8557V54.4897H59.2331V59.8705Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M104.666 54.5046V59.8705C104.666 60.8192 103.895 61.6048 102.932 61.6048C101.983 61.6048 101.197 60.834 101.197 59.8705C101.197 58.907 101.968 58.1362 102.932 58.1362C103.376 58.1362 103.776 58.2993 104.073 58.5661V57.4988C103.732 57.3358 103.332 57.232 102.932 57.232C101.479 57.232 100.308 58.403 100.308 59.8557C100.308 61.3084 101.479 62.4794 102.932 62.4794C104.384 62.4794 105.555 61.3084 105.555 59.8557V54.4897H104.666V54.5046Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M80.9638 57.2616C80.5487 57.2616 80.1633 57.3654 79.8224 57.5284V58.5957C80.1337 58.3289 80.5339 58.1658 80.9638 58.1658C81.9125 58.1658 82.6981 58.9366 82.6981 59.9001C82.6981 60.8636 81.9273 61.6344 80.9638 61.6344C80.0151 61.6344 79.2295 60.8636 79.2295 59.9001V54.5342H78.3401V59.8705C78.3401 61.3232 79.5111 62.4942 80.9638 62.4942C82.4165 62.4942 83.5875 61.3232 83.5875 59.8705C83.5875 58.4178 82.4016 57.2616 80.9638 57.2616Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M93.6079 57.2617C92.1553 57.2617 90.9843 58.4327 90.9843 59.8854C90.9843 61.3381 92.1553 62.5091 93.6079 62.5091C94.023 62.5091 94.4084 62.4054 94.7493 62.2423V61.175C94.438 61.4418 94.0378 61.6049 93.6079 61.6049C92.6593 61.6049 91.8736 60.8341 91.8736 59.8706C91.8736 58.9071 92.6444 58.1363 93.6079 58.1363C94.5566 58.1363 95.3422 58.9071 95.3422 59.8706V62.4795H96.2316V59.8706C96.2168 58.4327 95.0458 57.2617 93.6079 57.2617Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M63.7394 57.2617C62.2868 57.2617 61.1157 58.4327 61.1157 59.8854C61.1157 61.3381 62.2868 62.5091 63.7394 62.5091C64.1545 62.5091 64.5399 62.4054 64.8808 62.2423V61.175C64.5695 61.4418 64.1693 61.6049 63.7394 61.6049C62.7907 61.6049 62.0051 60.8341 62.0051 59.8706C62.0051 58.9071 62.7759 58.1363 63.7394 58.1363C64.6881 58.1363 65.4737 58.9071 65.4737 59.8706V62.4795H66.3631V59.8706C66.3483 58.4327 65.1773 57.2617 63.7394 57.2617Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M98.0845 57.6323V57.069H97.21V62.4942H98.0845V59.5C98.1735 57.7509 99.5817 57.7657 99.5817 57.7657V56.8911C99.5965 56.8911 98.4996 56.8318 98.0845 57.6323Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M74.8715 56.8318C74.3675 56.8318 73.8932 57.0097 73.5078 57.2914V53.719H72.6184V62.4943H73.5078V59.085H73.5226C73.5226 58.7144 73.6708 58.3883 73.908 58.1363C74.1452 57.8991 74.4861 57.7509 74.8567 57.7509C75.2273 57.7509 75.5534 57.8991 75.8054 58.1363C76.0425 58.3734 76.1908 58.7144 76.1908 59.085H76.2056V62.4943H77.095V59.085C77.095 58.4624 76.843 57.8991 76.4428 57.4989C76.0574 57.0838 75.4941 56.8318 74.8715 56.8318Z",
  fill: "#656565"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M71.4771 60.345C71.3585 60.2115 71.2251 60.1078 71.0917 60.0188C70.9583 59.9299 70.8101 59.8706 70.6322 59.7965H70.6174L68.7497 59.085C68.6162 59.0257 68.5125 58.9664 68.4236 58.9219C68.3643 58.8923 68.3198 58.8626 68.2901 58.833C68.2457 58.7885 68.2309 58.7737 68.216 58.7441C68.2012 58.7144 68.1864 58.6551 68.1864 58.5514C68.1864 58.4031 68.2457 58.2697 68.3198 58.1956C68.3643 58.1511 68.3939 58.1215 68.4384 58.1067C68.4828 58.0918 68.5273 58.077 68.5718 58.077H69.3278H71.2696V57.1876H69.3278H68.5718C68.3939 57.1876 68.216 57.2321 68.053 57.3062C67.8158 57.4248 67.6231 57.6027 67.5045 57.8102C67.3711 58.0325 67.297 58.2845 67.297 58.5662C67.297 58.7292 67.3118 58.8923 67.3711 59.0405C67.4156 59.1591 67.4749 59.2629 67.549 59.3518C67.6676 59.4852 67.801 59.589 67.9344 59.6779C68.0678 59.7669 68.216 59.8261 68.3939 59.9003H68.4087L70.2764 60.6118C70.4098 60.6711 70.5136 60.7304 70.6025 60.7748C70.6618 60.8045 70.7063 60.8341 70.7359 60.8638C70.7804 60.9082 70.7952 60.9231 70.8101 60.9527C70.8249 60.9823 70.8397 61.0416 70.8397 61.1454C70.8397 61.2936 70.7804 61.427 70.7063 61.5012C70.6618 61.5456 70.6322 61.5753 70.5877 61.5901C70.5432 61.6049 70.4988 61.6197 70.4543 61.6197H69.6983H67.6083V62.5091H69.6983H70.4543C70.6322 62.5091 70.8101 62.4647 70.9731 62.3905C71.2103 62.272 71.403 62.0941 71.5216 61.8866C71.655 61.6642 71.7291 61.4122 71.7291 61.1306C71.7291 60.9675 71.7143 60.8045 71.655 60.6562C71.6105 60.5377 71.5512 60.4339 71.4771 60.345Z",
  fill: "#656565"
}));
export default ReplenDashboardLogo;
