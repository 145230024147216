import React from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import ArrowLeftLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowLeftLineIcon.js";
import Settings3LineIcon from "../../../_snowpack/pkg/remixicon-react/Settings3LineIcon.js";
import ReplenScoutLogo from "../replen-scout-logo/index.js";
const Header = ({setIsSettingsOpen}) => {
  const history = useHistory();
  const handleGoBack = () => history.goBack();
  const headerButtonClass = "flex items-center justify-center p-1 border border-gray-200 rounded";
  return /* @__PURE__ */ React.createElement("header", {
    className: "bg-white shadow flex items-end justify-between px-4 py-2 h-16 border-b-2 border-gray-200"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "p-1 border border-gray-200 rounded",
    onClick: handleGoBack
  }, /* @__PURE__ */ React.createElement(ArrowLeftLineIcon, {
    className: "text-gray-500"
  })), /* @__PURE__ */ React.createElement(ReplenScoutLogo, {
    className: "mx-auto h-6 sm:h-10 w-auto mb-1"
  }), /* @__PURE__ */ React.createElement("button", {
    className: headerButtonClass,
    onClick: () => setIsSettingsOpen((isOpen) => !isOpen)
  }, /* @__PURE__ */ React.createElement(Settings3LineIcon, {
    className: "text-gray-500"
  })));
};
export default Header;
