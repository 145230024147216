import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {Disclosure, Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import ArrowUpSLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowUpSLineIcon.js";
import NumberFormat from "../../../_snowpack/pkg/react-number-format.js";
const CalculatorPanel = (props) => {
  const [costPrice, setCostPrice] = useState(void 0);
  const [fbmShippingFee, setFbmShippingFee] = useState(void 0);
  const [fbmFulfilmentFee, setFbmFulfilmentFee] = useState(void 0);
  const [fbmStorageFee, setFbmStorageFee] = useState(void 0);
  const {product, setting} = props;
  const handleFocus = (event) => event.target.select();
  const round = (number, precision) => {
    if (precision === void 0) {
      precision = 0;
    }
    var multiplicator = Math.pow(10, precision);
    const n = parseFloat((number * multiplicator).toFixed(11));
    var test = Math.round(n) / multiplicator;
    return +test.toFixed(precision);
  };
  const getItemPrice = (preferredPrice) => {
    const item = product;
    if (item[preferredPrice].current) {
      return item[preferredPrice].current;
    }
    if (item[preferredPrice].highest) {
      return item[preferredPrice].highest;
    }
    if (item[preferredPrice].lowest) {
      return item[preferredPrice].lowest;
    }
    if (item[preferredPrice].averageThirty) {
      return item[preferredPrice].averageThirty;
    }
    if (item[preferredPrice].averageNinety) {
      return item[preferredPrice].averageNinety;
    }
    if (item[preferredPrice].averageOneEighty) {
      return item[preferredPrice].averageOneEighty;
    }
    if (item[preferredPrice].lowest) {
      return item[preferredPrice].lowest;
    }
    if (item[preferredPrice].highest) {
      return item[preferredPrice].highest;
    }
    if (item[preferredPrice].average) {
      return item[preferredPrice].average;
    }
    return 0;
  };
  const calculateProfit = () => {
    const {profit} = product;
    const itemPrice = getItemPrice(setting.preferredPrice);
    const referralFee = profit.fees.referralFeePercentage * itemPrice;
    const rentalFee = profit.fees.rentalFee;
    const closingFee = profit.fees.closingFee;
    const storageFee = profit.fees.storageFee;
    const fbaFee = profit.fees.fbaFee;
    const fees = referralFee + rentalFee + closingFee + storageFee + fbaFee;
    let itemCost = round((itemPrice - setting.shippingCost - fees) / (1 + setting.preferredRoi / 100), 2);
    let tmpCost = costPrice === void 0 ? itemCost : costPrice;
    const netProfit = round(itemPrice - (tmpCost || 0) - setting.shippingCost - fees, 2);
    const margin = netProfit / itemPrice * 100;
    const roi = tmpCost ? netProfit / tmpCost * 100 : Number.NaN;
    return {
      itemPrice: round(itemPrice, 2),
      itemCost: round(itemCost, 2),
      costPrice: round(tmpCost, 2),
      shippingCost: round(setting.shippingCost, 2),
      fees: {
        referralFeePercentage: round(referralFee / itemPrice, 2),
        referralFee: round(referralFee, 2),
        rentalFee: round(rentalFee, 2),
        closingFee: round(closingFee, 2),
        storageFee: round(storageFee, 2),
        fbaFee: round(fbaFee, 2),
        total: round(fees, 2)
      },
      netProfit: round(netProfit, 2),
      margin: round(margin, 2),
      roi: round(roi, 2)
    };
  };
  const calculateProfitFbm = () => {
    const {profit} = product;
    const tmpShippingCost = fbmShippingFee === void 0 ? setting.shippingCost : fbmShippingFee;
    const itemPrice = getItemPrice(setting.preferredPrice);
    const referralFee = profit.fees.referralFeePercentage * (itemPrice + tmpShippingCost);
    const rentalFee = profit.fees.rentalFee;
    const closingFee = profit.fees.closingFee;
    const storageFee = fbmStorageFee === void 0 ? profit.fees.storageFee : fbmStorageFee;
    const fbaFee = fbmFulfilmentFee === void 0 ? profit.fees.fbaFee : fbmFulfilmentFee;
    const fees = referralFee + rentalFee + closingFee + storageFee + fbaFee;
    let itemCost = profit.itemCost;
    let tmpCost = costPrice === void 0 ? itemCost : costPrice;
    const netProfit = round(itemPrice + tmpShippingCost - (tmpCost || 0) - fees, 2);
    const margin = netProfit / (itemPrice + tmpShippingCost) * 100;
    const roi = tmpCost ? netProfit / tmpCost * 100 : Number.NaN;
    return {
      itemPrice: round(itemPrice, 2),
      itemCost: round(itemCost, 2),
      costPrice: round(tmpCost, 2),
      shippingCost: round(tmpShippingCost, 2),
      fees: {
        referralFeePercentage: round(referralFee / itemPrice, 2),
        referralFee: round(referralFee, 2),
        rentalFee: round(rentalFee, 2),
        closingFee: round(closingFee, 2),
        storageFee: round(storageFee, 2),
        fbaFee: round(fbaFee, 2),
        total: round(fees, 2)
      },
      netProfit: round(netProfit, 2),
      margin: round(margin, 2),
      roi: round(roi, 2)
    };
  };
  const calculatedProfit = product.profit.roi ? calculateProfit() : product.profit;
  const calculatedProfitFbm = product.profit.roi ? calculateProfitFbm() : product.profit;
  return /* @__PURE__ */ React.createElement(Disclosure, null, ({open}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: `${open ? "flex text-left pt-3" : "flex text-left py-3 border-b-2 border-gray-200"}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-1/12"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "text-sm"
  }, "Cost")), /* @__PURE__ */ React.createElement("div", {
    className: "w-3/12"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.costPrice,
    onChange: (e) => setCostPrice(parseFloat(e.target.value)),
    className: "text-xs w-16 h-5 mb-1 p-1 border-1 border-gray-400 rounded text-right",
    onFocus: handleFocus,
    displayType: "input",
    inputMode: "numeric",
    decimalSeparator: ".",
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, "Buy at", " ", /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.itemCost,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "w-8/12 relative flex pr-5"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-1/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.itemPrice,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, "Price")), /* @__PURE__ */ React.createElement("div", {
    className: "w-1/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.fees.total,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, "Fees")), /* @__PURE__ */ React.createElement("div", {
    className: "w-1/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.netProfit,
    className: round(calculatedProfit.roi) >= setting.preferredRoi ? "text-green-600 font-semibold" : "text-red-600 font-semibold",
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, "Profit")), /* @__PURE__ */ React.createElement("div", {
    className: "absolute right-0 top-0"
  }, /* @__PURE__ */ React.createElement(Disclosure.Button, null, /* @__PURE__ */ React.createElement(ArrowUpSLineIcon, {
    size: "1.25rem",
    color: "red",
    className: open ? "ml-auto mr-0 transform rotate-180" : "ml-auto mr-0"
  }))))), /* @__PURE__ */ React.createElement(Disclosure.Panel, {
    className: "border-b-2 border-gray-200 pb-5"
  }, /* @__PURE__ */ React.createElement("table", {
    className: "table table-fixed w-full border border-gray-300 shadow"
  }, /* @__PURE__ */ React.createElement("thead", {
    className: "bg-gray-200 text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600 w-6/12"
  }, "Description"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600 w-3/12 text-center"
  }, "FBA"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600 w-3/12 text-center"
  }, "FBM"))), /* @__PURE__ */ React.createElement("tbody", {
    className: "bg-white text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "Sales Price"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-gray-800 text-right"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.itemPrice,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-gray-800 text-right"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.itemPrice,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "Cost"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.costPrice,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.costPrice,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "Shipping Fee"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.shippingCost,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.shippingCost,
    onChange: (e) => setFbmShippingFee(parseFloat(e.target.value)),
    className: "text-xs w-16 h-5 mb-1 p-1 border-1 border-gray-400 rounded text-right",
    onFocus: handleFocus,
    displayType: "input",
    inputMode: "numeric",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "Referral"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.fees.referralFee,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.fees.referralFee,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "Storage"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.fees.storageFee,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.fees.storageFee,
    onChange: (e) => setFbmStorageFee(parseFloat(e.target.value)),
    className: "text-xs w-16 h-5 mb-1 p-1 border-1 border-gray-400 rounded text-right",
    onFocus: handleFocus,
    displayType: "input",
    inputMode: "numeric",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "Fulfilment Fee (Pick and Pack)"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.fees.fbaFee,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.fees.fbaFee,
    onChange: (e) => setFbmFulfilmentFee(parseFloat(e.target.value)),
    className: "text-xs w-16 h-5 mb-1 p-1 border-1 border-gray-400 rounded text-right",
    onFocus: handleFocus,
    displayType: "input",
    inputMode: "numeric",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 font-semibold text-gray-800"
  }, "Profit"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 font-semibold text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.netProfit,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 font-semibold text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.netProfit,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "ROI"), /* @__PURE__ */ React.createElement("td", {
    className: round(calculatedProfit.roi) >= setting.preferredRoi ? "p-1 text-sm text-right text-gray-800 bg-green-200" : "p-1 text-sm text-right text-gray-800 bg-red-200"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.roi,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    suffix: "%"
  })), /* @__PURE__ */ React.createElement("td", {
    className: round(calculatedProfitFbm.roi) >= setting.preferredRoi ? "p-1 text-sm text-right text-gray-800 bg-green-200" : "p-1 text-sm text-right text-gray-800 bg-red-200"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.roi,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    suffix: "%"
  }))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-xs text-gray-800"
  }, "Margin"), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfit.margin,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    suffix: "%"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "p-1 text-sm text-right text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: calculatedProfitFbm.margin,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    suffix: "%"
  }))))))));
};
export default CalculatorPanel;
