import axios from "../../../_snowpack/pkg/axios.js";
import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import SearchLineIcon from "../../../_snowpack/pkg/remixicon-react/SearchLineIcon.js";
import CloseLineIcon from "../../../_snowpack/pkg/remixicon-react/CloseLineIcon.js";
import SearchResultInput from "../search-result-input/index.js";
const DashboardSearch = () => {
  const [searchText, setSearchText] = React.useState("");
  const [searchHistories, setSearchHistories] = React.useState([]);
  const history = useHistory();
  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  const filterKeyword = (keyword) => {
    let keywordCopy = keyword;
    if (keywordCopy.includes("%")) {
      keywordCopy = keywordCopy.replaceAll("%", "%25");
    }
    return keywordCopy;
  };
  const onSearchSubmit = (e) => {
    e.preventDefault();
    history.push(`/results/${encodeURIComponent(filterKeyword(searchText))}`);
  };
  const getSearchHistory = () => {
    axios.get(`/api/v1/search-history`).then((result) => {
      setSearchHistories(result.data);
    });
  };
  useEffect(() => {
    getSearchHistory();
  }, []);
  const handleRemoveHistory = (keyword) => () => {
    axios.delete(`/api/v1/search-history/?keyword=${encodeURIComponent(keyword)}`).then(getSearchHistory);
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "w-full"
  }, /* @__PURE__ */ React.createElement("form", {
    onSubmit: onSearchSubmit
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex bg-white gap-2 mt-0.5 p-4 w-full relative"
  }, /* @__PURE__ */ React.createElement(SearchResultInput, {
    value: searchText,
    onChange: onSearchTextChange,
    placeholder: "Search"
  }), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "rounded text-gray-600 p-1 absolute right-6 top-4"
  }, /* @__PURE__ */ React.createElement(SearchLineIcon, {
    className: "h-7 w-7"
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-wrap mt-1 ml-1 p-3"
  }, searchHistories && searchHistories.length > 0 && searchHistories.slice(0, 20).map((ii) => {
    return /* @__PURE__ */ React.createElement("div", {
      key: ii.keyword,
      className: "relative inline align-middle border rounded-md bg-gray-200 pl-2 pr-6 py-0.5 my-0.5 mr-1"
    }, /* @__PURE__ */ React.createElement(Link, {
      className: "inline text-gray-500 text-xs hover:underline hover:text-blue-600",
      to: `/results/${encodeURIComponent(filterKeyword(ii.keyword))}`
    }, ii.keyword, " (", ii.resultCount || 0, ")"), /* @__PURE__ */ React.createElement("button", {
      className: "absolute right-1",
      onClick: handleRemoveHistory(ii.keyword)
    }, /* @__PURE__ */ React.createElement(CloseLineIcon, {
      className: "inline w-4 h-4"
    })));
  })));
};
export default DashboardSearch;
