import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import axios from "../../../_snowpack/pkg/axios.js";
import {useParams} from "../../../_snowpack/pkg/react-router.js";
import StarSFillIcon from "../../../_snowpack/pkg/remixicon-react/StarSFillIcon.js";
import StatPanel from "./stat-panel.js";
import CalculatorPanel from "./calculator-panel.js";
import CategoryPanel from "./category-panel.js";
import tmpProduct from "./tmp-product.js";
import AddToBuyListButton from "../add-to-buy-list-button/index.js";
const ProductDetail = (props) => {
  const {counter, history} = props;
  const params = useParams();
  const [statPanelStatus, setStatPanelStatus] = useState([]);
  const [graphUrl, setGraphUrl] = useState("");
  const [calculatorSetting, setCalculatorSetting] = useState();
  const [product, setProduct] = useState(tmpProduct);
  const [isAddToBuyListOpen, setIsAddToBuyListOpen] = useState(false);
  const getStatPanelStatus = async () => {
    const {data} = await axios.get("api/v1/settings/statpanel");
    const showingPanels = data.filter((stat) => stat.show);
    setStatPanelStatus(showingPanels);
  };
  const getProfitSettings = async () => {
    const {data} = await axios.get("/api/v1/settings/profit-calculator-setting");
    setCalculatorSetting({
      ...data,
      preferredRoi: parseFloat(data.preferredRoi),
      shippingCost: parseFloat(data.shippingCost)
    });
  };
  const getKeepaGraph = async () => {
    const response = await axios.get("api/v1/keepa/graph", {
      params: {
        asin: params.barcode
      }
    });
    setGraphUrl(response.data.graph);
  };
  const getProduct = async () => {
    const {data} = await axios.post(`api/v1/products/asin`, {asin: params.barcode});
    return setProduct(data);
  };
  useEffect(() => {
    getProduct();
    getProfitSettings();
  }, [counter]);
  useEffect(() => {
    getKeepaGraph();
    getStatPanelStatus();
  }, [product]);
  const StatPanelRow = ({rowTitle, product: product2}) => {
    switch (rowTitle) {
      case "Sales Rank":
        return /* @__PURE__ */ React.createElement(StatPanel, {
          title: rowTitle,
          stat: product2.salesRank,
          drop: product2.salesRankDrop
        });
      case "Sales Category":
        return /* @__PURE__ */ React.createElement(CategoryPanel, {
          title: rowTitle,
          categories: product2.categories
        });
      case "Buy Box":
        return /* @__PURE__ */ React.createElement(StatPanel, {
          title: rowTitle,
          stat: product2.buyBox
        });
      case "New":
        return /* @__PURE__ */ React.createElement(StatPanel, {
          title: rowTitle,
          stat: product2.new,
          offer: product2.newOffer
        });
      case "Used":
        return /* @__PURE__ */ React.createElement(StatPanel, {
          title: rowTitle,
          stat: product2.used,
          offer: product2.usedOffer
        });
      case "New FBA":
        return /* @__PURE__ */ React.createElement(StatPanel, {
          title: rowTitle,
          stat: product2.newFba,
          offer: product2.fbaOffer
        });
      case "New FBM":
        return /* @__PURE__ */ React.createElement(StatPanel, {
          title: rowTitle,
          stat: product2.newFbm,
          offer: product2.fbmOffer
        });
      default:
        return /* @__PURE__ */ React.createElement(React.Fragment, null);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "w-full flex flex-col"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-full flex justify-between p-5 bg-white"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-2/5 flex justify-center items-center bg-white p-2 mr-2"
  }, /* @__PURE__ */ React.createElement("img", {
    src: `https://images-na.ssl-images-amazon.com/images/I/${product.image}`,
    width: "150"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "w-3/5 ml-2"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "cust-black-color text-sm"
  }, product.title, product.brand && /* @__PURE__ */ React.createElement("span", {
    className: "text-gray-500 my-3 ml-2 px-2 text-sm border-l border-gray-300"
  }, product.brand)), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between items-center mt-2"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "cust-light-gray-color text-xs"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "text-xxs font-bold"
  }, "ASIN: "), " ", product.asin), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", {
    className: "flex items-center cust-light-gray-color text-xs mr-1.5"
  }, /* @__PURE__ */ React.createElement(StarSFillIcon, {
    size: "1.4em",
    color: "#ECC713"
  }), " ", product.rating.current, " ", /* @__PURE__ */ React.createElement("span", {
    className: "text-xxs pl-1"
  }, " ", "(", product.reviews.current, ")")))), /* @__PURE__ */ React.createElement("div", {
    className: "items-center"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "cust-light-gray-color text-xs"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "text-xxs font-bold"
  }, "Has Variations: "), product.isVariationParent ? " Yes" : " No"), product.categories && product.categories.length > 0 && /* @__PURE__ */ React.createElement("div", {
    className: "cust-light-gray-color text-xs"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "text-xxs font-bold"
  }, "Category: "), " ", product.categories[0].name)))), graphUrl && graphUrl !== "" && /* @__PURE__ */ React.createElement("div", {
    className: "bg-white container px-8 py-4 flex items-center justify-center"
  }, /* @__PURE__ */ React.createElement("a", {
    href: `https://keepa.com/#!product/1-${product.asin}`,
    target: "_blank"
  }, /* @__PURE__ */ React.createElement("img", {
    src: `data:image/png;base64,${graphUrl}`,
    alt: "keepa graph"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "w-full flex flex-col p-5 space-y-5 bg-white mb-3"
  }, calculatorSetting && /* @__PURE__ */ React.createElement(CalculatorPanel, {
    product,
    setting: calculatorSetting
  }), statPanelStatus && statPanelStatus.length > 0 && statPanelStatus.map(({title}, index) => /* @__PURE__ */ React.createElement(StatPanelRow, {
    product,
    rowTitle: title,
    key: `${title}-${index}`
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "fixed bottom-20 right-2"
  }, /* @__PURE__ */ React.createElement(AddToBuyListButton, {
    product
  })), /* @__PURE__ */ React.createElement("button", {
    onClick: () => history.goBack(),
    className: "self-center px-5 py-1 mt-3 text-primary font-medium"
  }, "Return to list"), /* @__PURE__ */ React.createElement("div", {
    className: "p-10"
  }));
};
export default ProductDetail;
