import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {useParams, useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {formatValue} from "../../../_snowpack/pkg/react-currency-input-field.js";
import axios from "../../../_snowpack/pkg/axios.js";
import SendToEmailButton from "./components/send-to-email-button/index.js";
import EditBuyListItemModal from "./components/edit-buy-list-item-modal/index.js";
import SendToReplenDashboard from "./components/send-to-replen-dashboard/index.js";
import {generateAmazonImageUrl} from "../../utils/generate-amazon-image-url.js";
const BuyList = ({isDashboardUser}) => {
  const [buyList, setBuyList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const params = useParams();
  const history = useHistory();
  const getBuyList = async () => {
    setHasError(false);
    setIsLoading(true);
    try {
      const products = await axios.get(`/api/v1/buy-list/${params.buyListId}`);
      setBuyList(products.data);
    } catch (err) {
      setHasError(true);
    }
    setIsLoading(false);
  };
  const handleEditModalOpen = (item) => () => {
    setSelectedItem(item);
    history.push(`/buy-lists/${buyList._id}/${item._id}`);
  };
  useEffect(() => {
    getBuyList();
  }, []);
  useEffect(() => {
    if (params?.buyListItemId && buyList) {
      const buyListItem = buyList.contents.find((item) => item._id === params.buyListItemId);
      setSelectedItem(buyListItem);
    }
  }, [params?.buyListItemId, buyList]);
  const formatCost = (cost) => formatValue({
    value: String(cost),
    prefix: "$",
    decimalScale: 2
  });
  const totalCost = buyList ? buyList.contents.reduce((total, item) => total + item.costPrice * item.qty, 0) : 0;
  const totalQty = buyList ? buyList.contents.reduce((total, item) => total + item.qty, 0) : 0;
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col w-full h-full bg-white relative"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "px-4 pt-4 ml-1 text-gray-800 font-medium text-lg"
  }, 'Buy List "', buyList?.name, '"'), isLoading ? /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-center"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "my-10"
  }, "Loading...")) : hasError ? /* @__PURE__ */ React.createElement("div", {
    className: "flex my-10 mx-4 justify-center text-center"
  }, "Something went wrong while ", /* @__PURE__ */ React.createElement("br", null), " loading your buy list.") : /* @__PURE__ */ React.createElement("div", {
    className: "p-4 my-2 flex-grow overflow-auto"
  }, buyList?.contents?.length > 0 ? React.Children.toArray(buyList.contents.map((product) => /* @__PURE__ */ React.createElement("div", {
    className: "flex py-4 border-b-2",
    onClick: handleEditModalOpen(product)
  }, /* @__PURE__ */ React.createElement("img", {
    className: "w-16 mr-2",
    src: generateAmazonImageUrl(product.image, 96)
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col flex-grow justify-between"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "line-clamp-2 text-sm pb-1"
  }, product.title), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between text-xs text-gray-500"
  }, /* @__PURE__ */ React.createElement("div", null, "Qty:", /* @__PURE__ */ React.createElement("span", {
    className: "font-medium text-gray-500"
  }, " ", product.qty)), /* @__PURE__ */ React.createElement("div", null, "Cost:", /* @__PURE__ */ React.createElement("span", {
    className: "font-medium text-gray-700"
  }, " ", formatCost(product.costPrice))), /* @__PURE__ */ React.createElement("div", null, "Subtotal:", /* @__PURE__ */ React.createElement("span", {
    className: "font-bold text-gray-700"
  }, " ", formatCost(product.costPrice * product.qty)))))))) : /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-center"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "my-10 italic"
  }, "Your buy list is empty."))), !isLoading && !hasError && /* @__PURE__ */ React.createElement("div", {
    className: "bg-gray-300 px-4 py-1.5 text-sm leading-none"
  }, "Total Cost:", /* @__PURE__ */ React.createElement("span", {
    className: "font-medium text-primary text-base"
  }, " ", formatCost(totalCost), " "), /* @__PURE__ */ React.createElement("span", {
    className: "text-gray-700 text-xs font-medium"
  }, "(", totalQty, " ", totalQty === 1 ? "item" : "items", ")")), !isLoading && !hasError && buyList?.contents.length > 0 && /* @__PURE__ */ React.createElement("div", {
    className: "absolute bottom-2 right-2 flex"
  }, isDashboardUser && /* @__PURE__ */ React.createElement(SendToReplenDashboard, {
    buyListId: buyList?._id
  }), /* @__PURE__ */ React.createElement(SendToEmailButton, {
    buyListId: buyList?._id
  })), /* @__PURE__ */ React.createElement(EditBuyListItemModal, {
    buyListItem: selectedItem,
    onSuccess: () => getBuyList()
  }));
};
export default BuyList;
