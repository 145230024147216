import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {Redirect, useHistory, useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import Header from "../header/index.js";
import Content from "../content/index.js";
import Footer from "../footer/index.js";
import WelcomeModal from "../welcome-modal/index.js";
import Settings from "../settings/index.js";
import BarcodeScanner from "../barcode-scanner/index.js";
const LoggedInContainer = ({user, handleLogout, isWelcomeShown, toggleIsWelcomeShown}) => {
  const history = useHistory();
  const {pathname} = useLocation();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (user && pathname === "/")
      history.push("/dashboard");
  }, [user, history, pathname]);
  const style = {
    height: "inherit",
    gridTemplateRows: "min-content 1fr 4rem",
    gridTemplateAreas: '"header" "main" "bottom-nav"'
  };
  const toggleIsScannerOpen = () => setIsScannerOpen(!isScannerOpen);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, user ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Settings, {
    isOpen: isSettingsOpen,
    setIsOpen: setIsSettingsOpen,
    user,
    handleLogout,
    setCounter
  }), /* @__PURE__ */ React.createElement(WelcomeModal, {
    isWelcomeShown,
    toggleIsWelcomeShown
  }), /* @__PURE__ */ React.createElement(BarcodeScanner, {
    isScannerOpen,
    toggleIsScannerOpen
  }), /* @__PURE__ */ React.createElement("div", {
    style,
    className: "grid"
  }, /* @__PURE__ */ React.createElement(Header, {
    setIsSettingsOpen
  }), /* @__PURE__ */ React.createElement(Content, {
    isDashboardUser: user.isDashboardUser,
    counter
  }), /* @__PURE__ */ React.createElement(Footer, {
    toggleIsScannerOpen
  }))) : /* @__PURE__ */ React.createElement(Redirect, {
    to: "/auth/login/scout"
  }));
};
export default LoggedInContainer;
