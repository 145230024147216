import React from "../../../_snowpack/pkg/react.js";
const ReplenScoutLogo = (props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "219",
    height: "34",
    viewBox: "0 0 219 34",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("title", null, "Replen Scout Logo"), /* @__PURE__ */ React.createElement("path", {
    d: "M2.79218 9.89089V8.09681H0V23.5096H2.79218V15.7974C3.08304 10.2404 7.55053 10.287 7.55053 10.287V7.52597C7.55053 7.52597 4.10684 7.30462 2.79218 9.89089Z",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M16.3343 21.3312C13.3908 21.3312 11.0058 18.9429 11.0058 15.9955C11.0058 13.0481 13.3908 10.6598 16.3343 10.6598C17.6955 10.6598 18.9287 11.1724 19.871 12.0112L12.2042 16.2634L13.5537 18.7099L22.4073 13.7937L23.8616 12.9782L23.8965 12.9549C23.8499 12.8733 23.8034 12.8034 23.7568 12.7335C22.5004 9.85599 19.65 7.8522 16.3226 7.8522C11.8435 7.8522 8.20203 11.487 8.20203 15.9839C8.20203 20.4691 11.8319 24.1155 16.3226 24.1155C20.604 24.1155 24.1059 20.7953 24.42 16.5896H21.6162C21.3253 19.2575 19.0683 21.3312 16.3343 21.3312Z",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M59.0315 21.3312C56.0881 21.3312 53.7031 18.9429 53.7031 15.9955C53.7031 13.0481 56.0881 10.6598 59.0315 10.6598C60.3927 10.6598 61.6259 11.1724 62.5683 12.0112L54.9014 16.2634L56.251 18.7099L65.1045 13.7937L66.5588 12.9782L66.5937 12.9549C66.5472 12.8733 66.5006 12.8034 66.4541 12.7335C65.1976 9.85599 62.3472 7.8522 59.0199 7.8522C54.5408 7.8522 50.8993 11.487 50.8993 15.9839C50.8993 20.4691 54.5291 24.1155 59.0199 24.1155C63.3012 24.1155 66.8031 20.7953 67.1172 16.5896H64.3134C64.0226 19.2575 61.7655 21.3312 59.0315 21.3312Z",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M34.5999 7.759C30.1207 7.759 26.4792 11.3938 26.4792 15.8906V30.2549H29.2714V22.0301V15.9023C29.2714 12.9549 31.6564 10.5783 34.5882 10.5783C37.5317 10.5783 39.905 12.9665 39.905 15.9023C39.905 18.8497 37.52 21.2263 34.5882 21.2263C32.9711 21.2263 31.5168 20.504 30.5396 19.3506V22.9388C31.7262 23.6262 33.1107 24.0222 34.5882 24.0222C39.0674 24.0222 42.7089 20.3875 42.7089 15.8906C42.7205 11.3938 39.0906 7.759 34.5999 7.759Z",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M48.2117 1.22345H45.4196V23.7311H48.2117V1.22345Z",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M76.5524 7.44443C72.8877 7.44443 69.9094 10.4268 69.9094 14.0965V23.7193H72.7016V14.0965C72.7016 11.9646 74.4234 10.2404 76.5524 10.2404C78.6815 10.2404 80.4033 11.9646 80.4033 14.0965V23.7193H83.1955V14.0965C83.1955 10.4268 80.2288 7.44443 76.5524 7.44443Z",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M68.4435 28.2976C67.3034 28.2976 66.3843 29.218 66.3843 30.3597C66.3843 31.5014 67.3034 32.4217 68.4435 32.4217C69.5837 32.4217 70.5028 31.5014 70.5028 30.3597C70.5028 29.218 69.572 28.2976 68.4435 28.2976ZM68.4435 31.7111C67.6989 31.7111 67.0823 31.1053 67.0823 30.348C67.0823 29.5908 67.6873 28.985 68.4435 28.985C69.1881 28.985 69.8047 29.5908 69.8047 30.348C69.8047 31.1053 69.1881 31.7111 68.4435 31.7111Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M46.4899 30.3481C46.4899 31.0937 45.8849 31.7111 45.1287 31.7111C44.3841 31.7111 43.7675 31.1053 43.7675 30.3481C43.7675 29.5908 44.3725 28.985 45.1287 28.985C45.4777 28.985 45.7918 29.1132 46.0245 29.3229V28.4841C45.7569 28.3559 45.4428 28.2744 45.1287 28.2744C43.9885 28.2744 43.0695 29.1947 43.0695 30.3364C43.0695 31.4781 43.9885 32.3985 45.1287 32.3985C46.2688 32.3985 47.1879 31.4781 47.1879 30.3364V26.1191H46.4899V30.3481Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M82.1485 26.1308V30.3481C82.1485 31.0937 81.5435 31.7111 80.7873 31.7111C80.0427 31.7111 79.4261 31.1053 79.4261 30.3481C79.4261 29.5908 80.0311 28.985 80.7873 28.985C81.1363 28.985 81.4504 29.1132 81.6831 29.3229V28.4841C81.4155 28.3559 81.1014 28.2744 80.7873 28.2744C79.6471 28.2744 78.728 29.1947 78.728 30.3364C78.728 31.4781 79.6471 32.3985 80.7873 32.3985C81.9274 32.3985 82.8465 31.4781 82.8465 30.3364V26.1191H82.1485V26.1308Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M63.5456 28.2978C63.2198 28.2978 62.9173 28.3793 62.6497 28.5075V29.3462C62.8941 29.1365 63.2082 29.0084 63.5456 29.0084C64.2902 29.0084 64.9068 29.6142 64.9068 30.3714C64.9068 31.1287 64.3018 31.7345 63.5456 31.7345C62.801 31.7345 62.1844 31.1287 62.1844 30.3714V26.1542H61.4863V30.3481C61.4863 31.4898 62.4054 32.4102 63.5456 32.4102C64.6857 32.4102 65.6048 31.4898 65.6048 30.3481C65.6048 29.2064 64.6741 28.2978 63.5456 28.2978Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M73.4694 28.2976C72.3293 28.2976 71.4102 29.218 71.4102 30.3597C71.4102 31.5014 72.3293 32.4217 73.4694 32.4217C73.7951 32.4217 74.0976 32.3402 74.3652 32.212V31.3732C74.1209 31.5829 73.8068 31.7111 73.4694 31.7111C72.7248 31.7111 72.1082 31.1053 72.1082 30.348C72.1082 29.5908 72.7132 28.985 73.4694 28.985C74.214 28.985 74.8306 29.5908 74.8306 30.348V32.3984H75.5286V30.348C75.517 29.218 74.5979 28.2976 73.4694 28.2976Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M50.0266 28.2976C48.8864 28.2976 47.9673 29.218 47.9673 30.3597C47.9673 31.5014 48.8864 32.4217 50.0266 32.4217C50.3523 32.4217 50.6548 32.3402 50.9224 32.212V31.3732C50.6781 31.5829 50.364 31.7111 50.0266 31.7111C49.282 31.7111 48.6654 31.1053 48.6654 30.348C48.6654 29.5908 49.2704 28.985 50.0266 28.985C50.7712 28.985 51.3878 29.5908 51.3878 30.348V32.3984H52.0858V30.348C52.0742 29.218 51.1551 28.2976 50.0266 28.2976Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M76.9829 28.589V28.1463H76.2965V32.4102H76.9829V30.0569C77.0527 28.6822 78.158 28.6939 78.158 28.6939V28.0065C78.1696 28.0065 77.3087 27.9599 76.9829 28.589Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M58.7639 27.9599C58.3684 27.9599 57.9961 28.0997 57.6936 28.321V25.5134H56.9955V32.4102H57.6936V29.7307H57.7052C57.7052 29.4394 57.8216 29.1831 58.0077 28.9851C58.1939 28.7987 58.4614 28.6822 58.7523 28.6822C59.0431 28.6822 59.2991 28.7987 59.4969 28.9851C59.683 29.1715 59.7993 29.4394 59.7993 29.7307H59.811V32.4102H60.509V29.7307C60.509 29.2414 60.3112 28.7987 59.9971 28.4841C59.6946 28.1579 59.2525 27.9599 58.7639 27.9599Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M56.0996 30.7209C56.0066 30.616 55.9019 30.5345 55.7972 30.4646C55.6925 30.3947 55.5761 30.3481 55.4365 30.2898H55.4249L53.959 29.7306C53.8543 29.684 53.7728 29.6374 53.703 29.6025C53.6565 29.5792 53.6216 29.5559 53.5983 29.5326C53.5634 29.4976 53.5518 29.486 53.5402 29.4627C53.5285 29.4394 53.5169 29.3928 53.5169 29.3112C53.5169 29.1947 53.5634 29.0899 53.6216 29.0316C53.6565 28.9967 53.6798 28.9734 53.7147 28.9617C53.7496 28.9501 53.7845 28.9384 53.8194 28.9384H54.4127H55.9368V28.2394H54.4127H53.8194C53.6798 28.2394 53.5402 28.2744 53.4122 28.3326C53.226 28.4258 53.0748 28.5656 52.9817 28.7287C52.877 28.9035 52.8188 29.1015 52.8188 29.3229C52.8188 29.451 52.8305 29.5792 52.877 29.6957C52.9119 29.7889 52.9585 29.8704 53.0166 29.9403C53.1097 30.0452 53.2144 30.1267 53.3191 30.1966C53.4238 30.2665 53.5402 30.3131 53.6798 30.3714H53.6914L55.1573 30.9306C55.262 30.9772 55.3434 31.0238 55.4132 31.0587C55.4598 31.082 55.4947 31.1053 55.5179 31.1286C55.5528 31.1636 55.5645 31.1752 55.5761 31.1985C55.5877 31.2218 55.5994 31.2684 55.5994 31.35C55.5994 31.4665 55.5528 31.5713 55.4947 31.6296C55.4598 31.6645 55.4365 31.6878 55.4016 31.6995C55.3667 31.7111 55.3318 31.7228 55.2969 31.7228H54.7036H53.0632V32.4218H54.7036H55.2969C55.4365 32.4218 55.5761 32.3868 55.7041 32.3286C55.8902 32.2354 56.0415 32.0956 56.1345 31.9325C56.2392 31.7577 56.2974 31.5597 56.2974 31.3383C56.2974 31.2102 56.2858 31.082 56.2392 30.9655C56.2043 30.8723 56.1578 30.7908 56.0996 30.7209Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M106.441 26.7781V30.989H115.559C123.156 30.989 126.871 28.8836 126.871 24.2618C126.871 20.4103 124.113 18.8183 119.16 16.9696L114.32 15.1722C112.294 14.4019 111.338 13.683 111.338 12.6046C111.338 11.2694 112.407 10.4991 114.264 10.4991H125.52V6.39081H115.559C109.593 6.39081 106.498 8.70171 106.498 12.6046C106.498 16.1993 108.805 17.8939 113.645 19.6913L118.26 21.386C120.68 22.3103 121.862 23.0293 121.862 24.4158C121.862 25.8537 120.511 26.7781 118.091 26.7781H106.441Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M146.023 10.4088V6.19781H143.603C139.832 6.19781 136.793 6.55729 133.698 8.45736C129.927 10.8196 127.789 14.4143 127.789 18.4712C127.789 25.6094 133.585 30.796 143.603 30.796H146.023V26.6364H143.941C136.962 26.6364 132.798 23.2471 132.798 18.5226C132.798 13.7981 136.906 10.4088 143.941 10.4088H146.023Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("rect", {
    width: "4.25994",
    height: "8.53104",
    transform: "matrix(0.738696 0.674039 -0.738696 0.674039 154.467 22.3674)",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("ellipse", {
    cx: "162.274",
    cy: "16.8226",
    rx: "3.88133",
    ry: "3.54161",
    fill: "#F93402"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M149.659 16.7995C149.659 19.9028 150.962 22.7746 153.412 24.9979C155.81 27.1749 158.885 28.3328 162.274 28.3328C165.662 28.3328 168.789 27.1749 171.187 24.9979C173.585 22.8209 174.888 19.9028 174.888 16.7995C174.888 13.6961 173.585 10.8243 171.187 8.64736C168.789 6.47038 165.662 5.31241 162.274 5.31241C158.885 5.31241 155.81 6.47038 153.412 8.64736C150.962 10.8707 149.659 13.6961 149.659 16.7995ZM154.298 16.7531C154.298 12.3992 157.895 9.06423 162.274 9.06423C166.652 9.06423 170.249 12.3992 170.249 16.7531C170.249 21.1071 166.652 24.4884 162.274 24.4884C157.895 24.4884 154.298 21.1071 154.298 16.7531Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M199.211 5.31241H194.427V19.5373C194.427 23.4915 191.613 26.1105 187.786 26.1105C183.959 26.1105 181.089 23.4915 181.089 19.5373V5.31241H176.305V18.7156C176.305 26.1618 181.033 30.3728 187.73 30.3728C194.427 30.3728 199.211 26.1618 199.211 18.7156V5.31241Z",
    fill: "#656565"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M201.534 0V21.9792C201.534 28.0902 205.361 31.1201 212.959 31.1201H214.535V26.9091H213.015C208.4 26.9091 206.374 25.1631 206.374 21.2089V10.6815H214.535V6.52187H206.374V0H201.534Z",
    fill: "#656565"
  }));
};
export default ReplenScoutLogo;
