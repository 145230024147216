import React from "../../../../../_snowpack/pkg/react.js";
const SendToEmailIcon = () => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "74",
    height: "74",
    viewBox: "0 0 74 74",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("g", {
    filter: "url(#filter0_d_1590_5066)"
  }, /* @__PURE__ */ React.createElement("circle", {
    cx: "37",
    cy: "36",
    r: "25",
    fill: "#F93402"
  })), /* @__PURE__ */ React.createElement("path", {
    d: "M27.2878 45.7222L48.1112 36.6944L27.2878 27.6667L27.2778 34.6883L42.1588 36.6944L27.2778 38.7006L27.2878 45.7222Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", {
    id: "filter0_d_1590_5066",
    x: "0",
    y: "0",
    width: "74",
    height: "74",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ React.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ React.createElement("feMorphology", {
    radius: "2",
    operator: "dilate",
    in: "SourceAlpha",
    result: "effect1_dropShadow_1590_5066"
  }), /* @__PURE__ */ React.createElement("feOffset", {
    dy: "1"
  }), /* @__PURE__ */ React.createElement("feGaussianBlur", {
    stdDeviation: "5"
  }), /* @__PURE__ */ React.createElement("feComposite", {
    in2: "hardAlpha",
    operator: "out"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_1590_5066"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect1_dropShadow_1590_5066",
    result: "shape"
  }))));
};
export default SendToEmailIcon;
