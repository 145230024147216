/**
 * Camera selector utility from (with some minor changes):
 * https://github.com/BlinkID/blinkid-in-browser/blob/c59900e573e7a40e2c3a23f06f05309a4b25cd9e/src/MicroblinkSDK/CameraUtils.ts
 */
export const backCameraKeywords = [
  "rear",
  "back",
  "rück",
  "arrière",
  "trasera",
  "trás",
  "traseira",
  "posteriore",
  "后面",
  "後面",
  "背面",
  "后置", // alternative
  "後置", // alternative
  "背置", // alternative
  "задней",
  "الخلفية",
  "후",
  "arka",
  "achterzijde",
  "หลัง",
  "baksidan",
  "bagside",
  "sau",
  "bak",
  "tylny",
  "takakamera",
  "belakang",
  "אחורית",
  "πίσω",
  "spate",
  "hátsó",
  "zadní",
  "darrere",
  "zadná",
  "задня",
  "stražnja",
  "belakang",
  "बैक"
];

export const PreferredCameraType = {
  /** Prefer back facing camera */
  BackFacingCamera: 0,
  /** Prefer front facing camera */
  FrontFacingCamera: 1,
}

export function isBackCameraLabel(label) {
  const lowercaseLabel = label.toLowerCase();

  return backCameraKeywords.some( keyword => lowercaseLabel.includes( keyword ) );
}

export class SelectedCamera {
  constructor(mdi, facing) {
    this.deviceId = mdi.deviceId;
    this.facing = facing;
    this.groupId = mdi.groupId;
    this.label = mdi.label;
  }
}

export async function getCameraDevices() {
  const frontCameras = [];
  const backCameras = [];
  {
    let devices = await navigator.mediaDevices.enumerateDevices();
    // if permission is not given, label of video devices will be empty string
    if (
      devices
        .filter((device) => device.kind === "videoinput")
        .every((device) => device.label === "")
    ) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: "environment" },
        },
        audio: false,
      });
      // enumerate devices again - now the label field should be non-empty, as we have a stream active
      // (even if we didn't get persistent permission for camera)
      devices = await navigator.mediaDevices.enumerateDevices();
      // close the stream, as we don't need it anymore
      stream.getTracks().forEach((track) => track.stop());
    }
    const cameras = devices.filter((device) => device.kind === "videoinput");
    for (const camera of cameras) {
      if (isBackCameraLabel(camera.label)) {
        backCameras.push(
          new SelectedCamera(camera, PreferredCameraType.BackFacingCamera)
        );
      } else {
        frontCameras.push(
          new SelectedCamera(camera, PreferredCameraType.FrontFacingCamera)
        );
      }
    }
  }
  return {
    frontCameras,
    backCameras,
  };
}

export async function selectCamera(cameraId, preferredCameraType = PreferredCameraType.BackFacingCamera) {
  const { frontCameras, backCameras } = await getCameraDevices();
  if (frontCameras.length > 0 || backCameras.length > 0) {
    // decide from which array the camera will be selected
    let cameraPool = backCameras.length > 0 ? backCameras : frontCameras;
    // if there is at least one back facing camera and user prefers back facing camera, use that as a selection pool
    if (
      preferredCameraType === PreferredCameraType.BackFacingCamera &&
      backCameras.length > 0
    ) {
      cameraPool = backCameras;
    }
    // if there is at least one front facing camera and is preferred by user, use that as a selection pool
    if (
      preferredCameraType === PreferredCameraType.FrontFacingCamera &&
      frontCameras.length > 0
    ) {
      cameraPool = frontCameras;
    }
    // otherwise use whichever pool is non-empty
    // sort camera pool by label
    cameraPool = cameraPool.sort((camera1, camera2) =>
      camera1.label.localeCompare(camera2.label)
    );
    // Check if cameras are labeled with resolution information, take the higher-resolution one in that case
    // Otherwise pick the first camera
    {
      let selectedCameraIndex = 0;
      const cameraResolutions = cameraPool.map((camera) => {
        const regExp = RegExp(/\b([0-9]+)MP?\b/, "i");
        const match = regExp.exec(camera.label);
        if (match !== null) {
          return parseInt(match[1], 10);
        } else {
          return NaN;
        }
      });

      if (
        !cameraResolutions.some((cameraResolution) => isNaN(cameraResolution))
      ) {
        selectedCameraIndex = cameraResolutions.lastIndexOf(
          Math.max(...cameraResolutions)
        );
      }

      if (cameraId) {
        let cameraDevice = null;

        cameraDevice = frontCameras.filter(
          (device) => device.deviceId === cameraId
        )[0];

        if (!cameraDevice) {
          cameraDevice = backCameras.filter(
            (device) => device.deviceId === cameraId
          )[0];
        }

        return cameraDevice || null;
      }

      return cameraPool[selectedCameraIndex];
    }
  } else {
    // no cameras available on the device
    return null;
  }
}
