import React, {useState, useEffect, useRef} from "../../../../../_snowpack/pkg/react.js";
import {XIcon, PlusIcon} from "../../../../../_snowpack/pkg/@heroicons/react/solid.js";
import axios from "../../../../../_snowpack/pkg/axios.js";
import Spinner from "../../../spinner/index.js";
import SendToEmailIcon from "../send-to-email-icon/index.js";
const SendToEmailButton = ({buyListId}) => {
  const [emailInput, setEmailInput] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const emailInputRef = useRef(null);
  const handleEmailDelete = (emailIndex) => () => {
    setEmailList((list) => list.filter((_, i) => i !== emailIndex));
  };
  const handleEmailAdd = (event) => {
    event.preventDefault();
    setEmailList((list) => [...new Set([...list, emailInput])]);
    setEmailInput("");
  };
  const sendBuyList = async () => {
    setIsSending(true);
    try {
      await axios.post(`/api/v1/buy-list/${buyListId}/send`, {emailList});
    } catch (err) {
      console.err(err);
    }
    setIsSent(true);
    setIsSending(false);
  };
  useEffect(() => {
    if (isOpen) {
      setIsSent(false);
      setIsSending(false);
      setEmailList([]);
      setEmailInput("");
      emailInputRef?.current?.focus();
    }
  }, [isOpen]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("button", {
    onClick: () => setIsOpen(true)
  }, /* @__PURE__ */ React.createElement(SendToEmailIcon, null)), /* @__PURE__ */ React.createElement("div", {
    className: `flex flex-col fixed inset-0 bg-white text-gray-800 z-30 transition-transform transform duration-300 ${isOpen ? "translate-x-0" : "-translate-x-full"}`
  }, isSent ? /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col h-full w-full justify-center items-center"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-2xl font-medium text-center text-gray-800"
  }, "Buy list successfully ", /* @__PURE__ */ React.createElement("br", null), " sent to recipients!"), /* @__PURE__ */ React.createElement("button", {
    onClick: () => setIsOpen(false),
    className: " text-white bg-primary flex-1 py-2 px-5 text-lg rounded font-medium flex-grow-0 mt-8"
  }, "Go Back")) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "mx-5 mt-5"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-row justify-between items-center mb-3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "text-xl font-semibold"
  }, "Send Buy List CSV"), /* @__PURE__ */ React.createElement("button", {
    className: "",
    onClick: () => setIsOpen(false)
  }, /* @__PURE__ */ React.createElement(XIcon, {
    className: "h-6"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "font-medium mt-6 mb-1 text-gray-600"
  }, "Add Email to Recipient List"), /* @__PURE__ */ React.createElement("form", {
    className: "flex gap-3 items-center relative",
    onSubmit: handleEmailAdd
  }, /* @__PURE__ */ React.createElement("input", {
    type: "email",
    placeholder: "Input the email to add...",
    required: true,
    ref: emailInputRef,
    value: emailInput,
    onInput: (e) => setEmailInput(e.target.value),
    className: "rounded-full text-sm w-full border-2 focus:border-primary-300 focus:ring-primary-300"
  }), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "absolute text-gray-600 right-2"
  }, /* @__PURE__ */ React.createElement(PlusIcon, {
    className: "w-8"
  })))), /* @__PURE__ */ React.createElement("p", {
    className: "text-center text-xl mt-4 font-medium"
  }, "Recipient List"), /* @__PURE__ */ React.createElement("div", {
    className: "my-2 px-4 overflow-auto flex-grow "
  }, emailList.map((email, i) => /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between py-3 px-3 rounded-lg odd:bg-gray-100",
    key: email
  }, email, /* @__PURE__ */ React.createElement("button", {
    className: "",
    onClick: handleEmailDelete(i)
  }, /* @__PURE__ */ React.createElement(XIcon, {
    className: "h-6"
  })))), emailList.length === 0 && /* @__PURE__ */ React.createElement("p", {
    className: "text-center mt-4"
  }, "Email recipient list is empty,", /* @__PURE__ */ React.createElement("br", null), " please add at least one email.")), /* @__PURE__ */ React.createElement("div", {
    className: "flex gap-4 w-full justify-between mt-auto mb-8 pt-2 px-4"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "text-gray-800 bg-gray-300 flex-1 p-2 rounded font-medium",
    onClick: () => setIsOpen(false)
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    disabled: isSending || emailList.length < 1,
    onClick: sendBuyList,
    className: "text-white bg-primary flex-1 p-2 rounded font-medium disabled:opacity-50 disabled:cursor-not-allowed"
  }, isSending ? /* @__PURE__ */ React.createElement(Spinner, {
    className: "h-6 text-white mx-auto"
  }) : "Send")))));
};
export default SendToEmailButton;
