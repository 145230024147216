import React from "../../../_snowpack/pkg/react.js";
const ReplenScoutIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: "53",
  height: "51",
  viewBox: "0 0 53 51",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("ellipse", {
  cx: "26.7711",
  cy: "23.4461",
  rx: "7.86053",
  ry: "7.1829",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("rect", {
  x: "7.9613",
  y: "36.5921",
  width: "8.80845",
  height: "10.4156",
  transform: "rotate(41.7841 7.9613 36.5921)",
  fill: "#F93402"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M0.75 23.3989C0.75 29.7203 3.41013 35.5701 8.41116 40.0989C13.3058 44.5333 19.5837 46.8921 26.5 46.8921C33.4163 46.8921 39.8006 44.5333 44.6952 40.0989C49.5899 35.6644 52.25 29.7203 52.25 23.3989C52.25 17.0774 49.5899 11.2277 44.6952 6.79322C39.8006 2.35876 33.4163 0 26.5 0C19.5837 0 13.3058 2.35876 8.41116 6.79322C3.41013 11.322 0.75 17.0774 0.75 23.3989ZM10.22 23.3045C10.22 14.4356 17.562 7.64238 26.5 7.64238C35.438 7.64238 42.78 14.4356 42.78 23.3045C42.78 32.1735 35.438 39.061 26.5 39.061C17.562 39.061 10.22 32.1735 10.22 23.3045Z",
  fill: "#656565"
}));
export default ReplenScoutIcon;
