const tmpProduct = {
  title:
    'Loading...',
  brand: '',
  image: '',
  asin: '',
  isVariationParent: false,
  rating: {
    current: 1,
    average: 1,
    averageThirty: 1,
    averageNinety: 1,
    averageOneEighty: 1,
    highest: 1,
    lowest: 1,
  },
  reviews: {
    current: 1,
    average: 1,
    averageThirty: 1,
    averageNinety: 1,
    averageOneEighty: 1,
    highest: 1,
    lowest: 1,
  },
  salesRank: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  buyBox: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  new: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  used: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  newFba: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  newFbm: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  newOffer: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  usedOffer: {
    current: 0,
    average: 0,
    averageThirty: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    highest: 0,
    lowest: 0,
  },
  fbaOffer: {
    current: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    averageThirty: 0,
  },
  fbmOffer: {
    current: 0,
    averageNinety: 0,
    averageOneEighty: 0,
    averageThirty: 0,
  },
  salesRankDrop: {
    averageNinety: 0,
    averageOneEighty: 0,
    averageThirty: 0,
  },
  categories: [
    {
      categoryId: 1,
      name: 'Loading...',
      ranking: 0,
      rankingRate: 0,
      totalProduct: 0,
    },
    {
      categoryId: 2,
      name: 'Loading...',
      ranking: 0,
      rankingRate: 0,
      totalProduct: 0,
    },
    {
      categoryId: 3,
      name: 'Loading...',
      ranking: 0,
      rankingRate: 0,
      totalProduct: 0,
    },
  ],
  profit: {
    itemPrice: 0,
    itemCost: 0,
    shippingCost: 0,
    fees: {
      referralFeePercentage: 0,
      referralFee: 0,
      rentalFee: 0,
      closingFee: 0,
      storageFee: 0,
      fbaFee: 0,
    },
    netProfit: 0,
    margin: 0,
    roi: 0,
  },
};

export default tmpProduct;
