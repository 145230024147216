import { c as createCommonjsModule, g as getDefaultExportFromCjs, a as commonjsGlobal } from './common/_commonjsHelpers-8c19dec8.js';

var common = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateCheckDigit = exports.FORMAT_ERR = exports.EMPTY_CODE_ERR = exports.NONSTRING_ERR = void 0;
exports.NONSTRING_ERR = 'Barcode must be a string';
exports.EMPTY_CODE_ERR = 'Barcode must not be an empty string';
exports.FORMAT_ERR = 'Barcode is not of a valid format';
function to13Digits(barcode) {
    return barcode.padStart(13, '0');
}
// This assumes the barcode does not have a check digit,
// AND has a number system on it.
function generateCheckDigit(barcode) {
    return String((10 - ((to13Digits(barcode)
        .split('')
        .map((num, idx) => ((+num) * ((idx % 2 === 0) ? 3 : 1)))
        .reduce((prev, cur) => prev + cur)) % 10)) % 10);
}
exports.generateCheckDigit = generateCheckDigit;
});

var upcE = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });
exports.compress = exports.expand = void 0;

function expand(barcode) {
    if (typeof barcode !== 'string')
        throw new Error(common.NONSTRING_ERR);
    if (!/^\d{6,8}$/.test(barcode))
        throw new Error(common.FORMAT_ERR);
    return setCheckDigit(setNumberSystem(expandBarcode(barcode)));
}
exports.expand = expand;
function expandBarcode(barcode) {
    const digits = barcode.split('');
    const numberSystem = (digits.length > 7) ? digits.shift() : '';
    const checkDigit = (digits.length > 6) ? digits.pop() : '';
    const lastDigit = +digits.pop();
    let expanded;
    switch (lastDigit) {
        case 0:
        case 1:
        case 2:
            expanded = digits.slice(0, 2).join('') + lastDigit + '0000' + digits.slice(2, 5).join('');
            break;
        case 3:
        case 4:
            expanded = digits.slice(0, lastDigit).join('') + '00000' + digits.slice(lastDigit, 5).join('');
            break;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            expanded = digits.join('') + '0000' + lastDigit;
            break;
    }
    return numberSystem + expanded + checkDigit;
}
function compress(barcode) {
    if (typeof barcode !== 'string')
        throw new Error(common.NONSTRING_ERR);
    if (!/^\d{10,12}$/.test(barcode))
        throw new Error(common.FORMAT_ERR);
    return compressBarcode(setCheckDigit(setNumberSystem(barcode)));
}
exports.compress = compress;
function compressBarcode(barcode) {
    const v1 = /^(\d{3})([0-2])0{4}(\d{3})(\d{1})$/;
    const v2 = /^(\d{3})([3-9])0{5}(\d{2})(\d{1})$/;
    const v3 = /^(\d{5})0{5}(\d{1})(\d{1})$/;
    const v4 = /^(\d{6})0{4}(\d{2})$/;
    let rgx;
    rgx = v1.exec(barcode);
    if (rgx) {
        return rgx[1] + rgx[3] + rgx[2] + rgx[4];
    }
    rgx = v2.exec(barcode);
    if (rgx) {
        return rgx[1] + rgx[2] + rgx[3] + '3' + rgx[4];
    }
    rgx = v3.exec(barcode);
    if (rgx) {
        return rgx[1] + rgx[2] + '4' + rgx[3];
    }
    rgx = v4.exec(barcode);
    if (rgx) {
        return rgx[1] + rgx[2];
    }
    return null;
}
function setCheckDigit(barcode) {
    return ((barcode.length !== 12 && barcode.length !== 8)
        ? barcode + common.generateCheckDigit(barcode)
        : barcode);
}
function setNumberSystem(barcode) {
    return ((barcode.length !== 12 && barcode.length !== 8)
        ? '0' + barcode
        : barcode);
}
});

var lib = createCommonjsModule(function (module, exports) {
var __createBinding = (commonjsGlobal && commonjsGlobal.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (commonjsGlobal && commonjsGlobal.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (commonjsGlobal && commonjsGlobal.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRealFormat = exports.minify = exports.getFormat = exports.isValid = exports.isGTIN = exports.upcE = void 0;

const upcE$1 = __importStar(upcE);
exports.upcE = upcE$1;
function isGTIN(barcode) {
    if (typeof barcode !== 'string')
        throw new Error(common.NONSTRING_ERR);
    if (barcode === '')
        throw new Error(common.EMPTY_CODE_ERR);
    return (/^(\d{12,14}|\d{8})$/.test(barcode));
}
exports.isGTIN = isGTIN;
function assertIsGTIN(barcode) {
    if (!isGTIN(barcode))
        throw new Error(common.FORMAT_ERR);
}
function isValid(barcode) {
    assertIsGTIN(barcode);
    const checkDigit = barcode.slice(-1);
    const generatedCheckDigit = common.generateCheckDigit(barcode.slice(0, -1));
    return checkDigit === generatedCheckDigit;
}
exports.isValid = isValid;
function getFormat(barcode) {
    assertIsGTIN(barcode);
    return `GTIN-${barcode.length}`;
}
exports.getFormat = getFormat;
function minify(barcode) {
    assertIsGTIN(barcode);
    const format = getFormat(barcode);
    switch (format) {
        case 'GTIN-14':
            return barcode.replace(/^(0{6}|0{1,2})/, '');
        case 'GTIN-13':
            return barcode.replace(/^(0{5}|0{1})/, '');
        case 'GTIN-12':
            return barcode.replace(/^0{4}/, '');
        case 'GTIN-8':
            return barcode;
    }
    throw new Error('Unknown barcode format');
}
exports.minify = minify;
function getRealFormat(barcode) {
    return getFormat(minify(barcode));
}
exports.getRealFormat = getRealFormat;
});

var __pika_web_default_export_for_treeshaking__ = /*@__PURE__*/getDefaultExportFromCjs(lib);

export default __pika_web_default_export_for_treeshaking__;
