import React from "../../../_snowpack/pkg/react.js";
import {Disclosure, Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import ArrowUpLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowUpLineIcon.js";
import ArrowDownLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowDownLineIcon.js";
import ArrowUpSLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowUpSLineIcon.js";
import ArrowPercent from "./arrow-percent.js";
import NumberFormat from "../../../_snowpack/pkg/react-number-format.js";
const StatPanel = (props) => {
  const {current, highest, lowest} = props.stat;
  const {averageThirty, averageNinety, averageOneEighty} = props.stat;
  return /* @__PURE__ */ React.createElement(Disclosure, null, ({open}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Disclosure.Button, null, /* @__PURE__ */ React.createElement("div", {
    className: `${open ? "flex text-left pt-3" : "flex text-left py-3 border-b-2 border-gray-200"}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-1/4"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "text-sm"
  }, props.title)), /* @__PURE__ */ React.createElement("div", {
    className: "w-3/4 relative flex pr-5"
  }, current && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "w-1/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value"
  }, props.title == "Sales Rank" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: current,
    displayType: "text",
    thousandSeparator: true
  })) : /* @__PURE__ */ React.createElement(NumberFormat, {
    value: current,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, "Current"))), highest && /* @__PURE__ */ React.createElement("div", {
    className: "w-1/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value text-green-900"
  }, props.title == "Sales Rank" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: highest,
    displayType: "text",
    thousandSeparator: true
  })) : /* @__PURE__ */ React.createElement(NumberFormat, {
    value: highest,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, props.title == "Sales Rank" ? "Highest" : "Highest Offer", " ")), lowest && /* @__PURE__ */ React.createElement("div", {
    className: "w-1/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value text-red-900"
  }, props.title == "Sales Rank" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: lowest,
    displayType: "text",
    thousandSeparator: true
  })) : /* @__PURE__ */ React.createElement(NumberFormat, {
    value: lowest,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, props.title == "Sales Rank" ? "Lowest" : "Lowest Offer", " ")), /* @__PURE__ */ React.createElement("div", {
    className: "absolute right-0 top-0"
  }, /* @__PURE__ */ React.createElement(ArrowUpSLineIcon, {
    size: "1.25rem",
    color: "red",
    className: open ? "ml-auto mr-0 transform rotate-180" : "ml-auto mr-0"
  }))))), /* @__PURE__ */ React.createElement(Disclosure.Panel, {
    className: "border-b-2 border-gray-200 pb-5"
  }, /* @__PURE__ */ React.createElement("div", null, current || averageThirty || averageNinety || averageOneEighty ? /* @__PURE__ */ React.createElement("table", {
    className: "table table-fixed w-full border border-gray-300 shadow"
  }, /* @__PURE__ */ React.createElement("thead", {
    className: "bg-gray-200 text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "30 days avg."), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "90 days"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "180 days"))), /* @__PURE__ */ React.createElement("tbody", {
    className: "bg-white text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, props.title == "Sales Rank" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: averageThirty,
    displayType: "text"
  })) : /* @__PURE__ */ React.createElement(NumberFormat, {
    value: averageThirty,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }), /* @__PURE__ */ React.createElement(ArrowPercent, {
    value: (averageThirty - current) / averageThirty
  })), /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, props.title == "Sales Rank" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: averageNinety,
    displayType: "text"
  })) : /* @__PURE__ */ React.createElement(NumberFormat, {
    value: averageNinety,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }), /* @__PURE__ */ React.createElement(ArrowPercent, {
    value: (averageNinety - current) / averageNinety
  })), /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, props.title == "Sales Rank" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: averageOneEighty,
    displayType: "text"
  })) : /* @__PURE__ */ React.createElement(NumberFormat, {
    value: averageOneEighty,
    displayType: "text",
    decimalSeparator: ".",
    thousandSeparator: true,
    allowNegative: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: "$"
  }), /* @__PURE__ */ React.createElement(ArrowPercent, {
    value: (averageOneEighty - current) / averageOneEighty
  }))))) : /* @__PURE__ */ React.createElement(React.Fragment, null), props.offer && (props.offer.current || props.offer.averageThirty || props.offer.averageNinety || props.offer.averageOneEighty) ? /* @__PURE__ */ React.createElement("table", {
    className: "table table-fixed w-full border border-gray-300 shadow mt-3"
  }, /* @__PURE__ */ React.createElement("thead", {
    className: "bg-gray-200 text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "Current Offer"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "Highest Offer"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "Lowest Offer"))), /* @__PURE__ */ React.createElement("tbody", {
    className: "bg-white text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, props.offer.current), /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, props.offer.highest), /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, props.offer.lowest)))) : /* @__PURE__ */ React.createElement(React.Fragment, null), props.drop && (props.drop.averageThirty || props.drop.averageNinety || props.drop.averageOneEighty) ? /* @__PURE__ */ React.createElement("table", {
    className: "table table-fixed w-full border border-gray-300 shadow mt-3"
  }, /* @__PURE__ */ React.createElement("thead", {
    className: "bg-gray-200 text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "30 days drop"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "90 days"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600"
  }, "180 days"))), /* @__PURE__ */ React.createElement("tbody", {
    className: "bg-white text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: props.drop.averageThirty,
    displayType: "text",
    decimalScale: 0
  })), /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: props.drop.averageNinety,
    displayType: "text"
  })), /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-sm text-gray-800"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: props.drop.averageOneEighty,
    displayType: "text",
    decimalScale: 0
  }))))) : /* @__PURE__ */ React.createElement(React.Fragment, null)))));
};
export default StatPanel;
