import React from "../../../_snowpack/pkg/react.js";
import {NavLink} from "../../../_snowpack/pkg/react-router-dom.js";
import QrScan2LineIcon from "../../../_snowpack/pkg/remixicon-react/QrScan2LineIcon.js";
import SearchLineIcon from "../../../_snowpack/pkg/remixicon-react/SearchLineIcon.js";
import StackLineIcon from "../../../_snowpack/pkg/remixicon-react/StackLineIcon.js";
const Footer = ({toggleIsScannerOpen}) => {
  return /* @__PURE__ */ React.createElement("footer", {
    className: "bg-white shadow flex items-center justify-around text-sm"
  }, /* @__PURE__ */ React.createElement(NavLink, {
    to: "/dashboard",
    className: "flex flex-col items-center p-2 text-gray-500",
    activeClassName: "text-primary"
  }, /* @__PURE__ */ React.createElement(SearchLineIcon, null), /* @__PURE__ */ React.createElement("span", null, "Search")), /* @__PURE__ */ React.createElement("button", {
    className: "flex flex-col items-center p-2 text-gray-500",
    onClick: toggleIsScannerOpen
  }, /* @__PURE__ */ React.createElement(QrScan2LineIcon, null), /* @__PURE__ */ React.createElement("span", null, "Scan")), /* @__PURE__ */ React.createElement(NavLink, {
    to: "/buy-lists",
    className: "flex flex-col items-center p-2 text-gray-500",
    activeClassName: "text-primary"
  }, /* @__PURE__ */ React.createElement(StackLineIcon, null), /* @__PURE__ */ React.createElement("span", null, "Buy Lists")));
};
export default Footer;
