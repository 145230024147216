import React, {Fragment, useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {ExclamationCircleIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
import {CheckCircleIcon} from "../../../_snowpack/pkg/@heroicons/react/outline.js";
import {Dialog, Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import axios from "../../../_snowpack/pkg/axios.js";
import Spinner from "../spinner/index.js";
import SettingsInput from "../settings/components/settings-input/index.js";
import ReplenScoutIcon from "../replen-scout-icon/index.js";
export default function WelcomeModal({isWelcomeShown, toggleIsWelcomeShown}) {
  const [isOpen, setIsOpen] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [keepaApiKey, setKeepaApiKey] = useState("");
  const [preferredRoi, setPreferredRoi] = useState(null);
  const [shippingCost, setShippingCost] = useState(null);
  const [preferredPrice, setPreferredPrice] = useState("buyBox");
  const [profitCalculatorError, setProfitCalculatorError] = useState(null);
  const [keepaApiKeyError, setKeepaApiKeyError] = useState(null);
  const submitProfitCalculator = async () => {
    setIsSaving(true);
    const {status, data: message} = await axios.post("/api/v1/settings/profit-calculator-setting", {
      preferredRoi,
      shippingCost,
      preferredPrice
    }, {
      validateStatus: () => true
    });
    if (status !== 200) {
      setProfitCalculatorError(message);
    }
    setIsSaving(false);
  };
  const submitKeepaApiKey = async () => {
    setIsSaving(true);
    const {status, data: message} = await axios.post("/api/v1/settings/keepa-api-key", {
      keepaApiKey
    }, {
      validateStatus: () => true
    });
    if (status !== 200) {
      setKeepaApiKeyError(message);
    } else {
      setIsValid(true);
    }
    setIsSaving(false);
    await submitProfitCalculator();
  };
  const checkKeepaApiKeyStatus = async () => {
    const {status, data: message} = await axios.get("/api/v1/settings/keepa-api-key/status", {
      validateStatus: () => true
    });
    if (status !== 200) {
      setKeepaApiKeyError(message);
    } else {
      setIsValid(true);
    }
    setIsChecked(true);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    submitKeepaApiKey();
  };
  const handleCloseModal = () => {
    if (isValid) {
      setIsOpen(false);
      toggleIsWelcomeShown();
    }
  };
  useEffect(() => {
    checkKeepaApiKeyStatus();
  }, []);
  const isModalOpen = isOpen && isChecked && isWelcomeShown && isValid || !isValid && isChecked;
  const renderProfitSetting = () => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(SettingsInput, {
    label: "Minimum ROI",
    name: "preferredRoi",
    placeholder: "Ideal ROI percentage",
    value: preferredRoi,
    type: "text",
    onInput: (e) => setPreferredRoi(e.target.value),
    error: profitCalculatorError,
    required: true
  }), /* @__PURE__ */ React.createElement("div", {
    className: "mt-2"
  }), /* @__PURE__ */ React.createElement(SettingsInput, {
    label: "Inbound Shipping Cost",
    name: "shippingCost",
    placeholder: "Average inbound shipping cost per item",
    value: shippingCost,
    type: "text",
    onInput: (e) => setShippingCost(e.target.value),
    error: profitCalculatorError,
    required: true
  }), /* @__PURE__ */ React.createElement("div", {
    className: "mt-2"
  }), /* @__PURE__ */ React.createElement(SettingsInput, {
    label: "Preferred Base Price",
    name: "preferredPrice",
    options: [
      {title: "Buy Box", value: "buyBox"},
      {title: "New", value: "new"},
      {title: "Used", value: "used"},
      {title: "New FBA", value: "newFba"},
      {title: "New FBM", value: "newFbm"}
    ],
    value: preferredPrice || "buyBox",
    type: "select",
    onInput: (e) => setPreferredPrice(e.target.value),
    error: profitCalculatorError
  }));
  return /* @__PURE__ */ React.createElement(Transition.Root, {
    show: isModalOpen,
    as: Fragment
  }, /* @__PURE__ */ React.createElement(Dialog, {
    as: "div",
    static: true,
    className: "fixed z-10 inset-0 overflow-y-auto",
    open: isModalOpen,
    onClose: handleCloseModal
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement(Dialog.Overlay, {
    className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  })), /* @__PURE__ */ React.createElement("span", {
    className: "hidden sm:inline-block sm:align-middle sm:h-screen",
    "aria-hidden": "true"
  }, "​"), /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
    enterTo: "opacity-100 translate-y-0 sm:scale-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
    leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "my-3 text-center sm:mt-5"
  }, /* @__PURE__ */ React.createElement(Dialog.Title, {
    as: "h3",
    className: "inline-flex flex-col gap-2 items-center leading-6 text-gray-900"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "font-bold text-2xl mb-4"
  }, "Welcome to"), /* @__PURE__ */ React.createElement(ReplenScoutIcon, {
    "aria-hidden": "true"
  }), /* @__PURE__ */ React.createElement("span", {
    className: "font-medium text-3xl"
  }, "Replen Scout")))), isChecked ? isValid ? /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col items-center gap-1 py-2"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-center mb-4"
  }, "Your Keepa API key is valid!", /* @__PURE__ */ React.createElement("br", null), " Please proceed on using the app."), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "inline-flex items-center px-4 py-2 border shadow-sm text-base rounded text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400",
    onClick: handleCloseModal
  }, "Dismiss")) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "my-6"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-sm text-gray-500 w-3/4 mx-auto text-center sm:w-auto"
  }, "To get started, please provide the needed details below.")), /* @__PURE__ */ React.createElement("form", {
    className: "mt-5 sm:mt-6",
    onSubmit: submitHandler
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "keepaApiKey",
    className: "block font-medium text-gray-800"
  }, "Keepa API Key ", /* @__PURE__ */ React.createElement("span", {
    className: "text-primary"
  }, "*")), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1 relative rounded-md shadow-sm"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "text",
    name: "keepaApiKey",
    value: keepaApiKey,
    onInput: (e) => setKeepaApiKey(e.target.value),
    className: "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
    placeholder: "Input API key with an active plan"
  }), keepaApiKeyError ? /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
  }, /* @__PURE__ */ React.createElement(ExclamationCircleIcon, {
    className: "h-5 w-5 text-red-500",
    "aria-hidden": "true"
  })) : null), /* @__PURE__ */ React.createElement("p", {
    className: "mt-1 mb-4 text-sm text-red-600"
  }, keepaApiKeyError)), renderProfitSetting(), /* @__PURE__ */ React.createElement("p", {
    className: "text-center text-sm text-gray-500 my-6"
  }, "Update this value anytime in the Settings."), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    disabled: isSaving,
    className: "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-500 text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
  }, isSaving ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Spinner, {
    className: "-ml-1 mr-3 h-5 w-auto text-white"
  }), " Saving...") : "Save"))) : /* @__PURE__ */ React.createElement(Spinner, {
    className: "h-16 w-auto m-auto text-primary"
  }))))));
}
