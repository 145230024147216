import React, {useEffect, useRef, useState} from "../../../_snowpack/pkg/react.js";
import Quagga from "../../../_snowpack/pkg/@ericblade/quagga2.js";
import gtin from "../../../_snowpack/pkg/gtin.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {PauseIcon, PlayIcon, StopIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
import LightningBoltSolidIcon from "../../../_snowpack/pkg/@heroicons/react/solid/LightningBoltIcon.js";
import LightningBoltOutlineIcon from "../../../_snowpack/pkg/@heroicons/react/outline/LightningBoltIcon.js";
import {selectCamera} from "../../utils/camera.js";
import "./index.css";
const getModeOfCodeErrors = (decodedCodes) => {
  if (decodedCodes.length === 0)
    return null;
  const modeMap = {};
  let mode = decodedCodes[0], maxCount = 1;
  for (const current of decodedCodes) {
    if (typeof modeMap[current] === "undefined")
      modeMap[current] = 1;
    else
      modeMap[current]++;
    if (modeMap[current] > maxCount) {
      mode = current;
      maxCount = modeMap[current];
    }
  }
  return mode;
};
const portraitMediaQuery = window.matchMedia("(orientation: portrait)");
const BarcodeScanner = ({isScannerOpen, toggleIsScannerOpen}) => {
  const scannerRef = useRef();
  const codesRef = useRef([]);
  const history = useHistory();
  const [isPaused, setIsPaused] = useState(false);
  const [zoomSettings, setZoomSettings] = useState(null);
  const [torchSettings, setTorchSettings] = useState(null);
  const initScanner = async () => {
    const camera = await selectCamera();
    const isPortrait = portraitMediaQuery.matches;
    const constraints = {
      width: {ideal: isPortrait ? window.innerHeight : window.innerWidth},
      height: {ideal: isPortrait ? window.innerWidth : window.innerHeight},
      resizeMode: "crop-and-scale"
    };
    if (camera.deviceId === "") {
      constraints.facingMode = {ideal: "environment"};
    } else {
      constraints.deviceId = camera.deviceId;
    }
    Quagga.init({
      locate: false,
      decoder: {
        readers: ["upc_reader", "ean_reader", "ean_8_reader"]
      },
      inputStream: {
        type: "LiveStream",
        target: scannerRef.current,
        constraints,
        area: {
          top: "30%",
          right: "10%",
          left: "10%",
          bottom: "30%"
        }
      }
    }, async (err) => {
      if (err) {
        console.error(err);
        handleScannerClose();
      } else {
        Quagga.onProcessed(handleProcessed);
        Quagga.onDetected(handleDetected);
        Quagga.start();
        const track = Quagga.CameraAccess.getActiveTrack();
        const capabilities = track.getCapabilities();
        const settings = track.getSettings();
        const imageCapture = new ImageCapture(track);
        const photoCapabilities = await imageCapture.getPhotoCapabilities();
        const isTorchSupported = !!photoCapabilities.torch || "fillLightMode" in photoCapabilities && photoCapabilities.fillLightMode.length != 0 && photoCapabilities.fillLightMode != "none";
        if (isTorchSupported) {
          setTorchSettings({
            enabled: settings.torch
          });
        }
        if ("zoom" in settings) {
          setZoomSettings({
            min: capabilities.zoom.min,
            max: capabilities.zoom.max,
            step: capabilities.zoom.step,
            value: settings.zoom
          });
        }
        redrawOverlay();
      }
    });
  };
  const stopScanner = async () => {
    Quagga.offProcessed(handleProcessed);
    Quagga.offDetected(handleDetected);
    await Quagga.stop();
  };
  const handleScannerClose = () => {
    stopScanner();
    toggleIsScannerOpen();
    setIsPaused(false);
  };
  const toggleScannerPause = () => {
    if (isPaused) {
      setIsPaused(false);
      return Quagga.start();
    }
    setIsPaused(true);
    return Quagga.pause();
  };
  const restartScanner = async () => {
    await stopScanner();
    initScanner();
  };
  const redrawOverlay = () => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    const ctxWidth = drawingCanvas.width;
    const ctxHeight = drawingCanvas.height;
    drawingCtx.clearRect(0, 0, ctxWidth, ctxHeight);
    drawingCtx.beginPath();
    drawingCtx.rect(0, 0, ctxWidth, ctxHeight);
    drawingCtx.fillStyle = "#FFFFFF88";
    drawingCtx.fill();
  };
  const handleProcessed = (result) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    if (result?.boxes) {
      result.boxes.filter((box) => box !== result.box).forEach((box) => {
        const lineWidth = 4;
        const boxObj = {
          x: box[0][0] + lineWidth / 2,
          y: box[0][1] + lineWidth / 2,
          w: box[2][0] - box[0][0] - lineWidth,
          h: box[2][1] - box[0][1] - lineWidth
        };
        Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {
          color: "#111111",
          lineWidth
        });
        const hh = boxObj.h * 0.4;
        drawingCtx.clearRect(boxObj.x - lineWidth, boxObj.y + hh / 2, boxObj.w + lineWidth + lineWidth, boxObj.h - hh);
        const ww = boxObj.w * 0.2;
        drawingCtx.clearRect(boxObj.x + ww / 2, boxObj.y - lineWidth, boxObj.w - ww, boxObj.h + lineWidth + lineWidth);
        drawingCtx.clearRect(boxObj.x, boxObj.y, boxObj.w, boxObj.h);
      });
    }
  };
  const handleDetected = (result) => {
    const cachedCodes = codesRef.current;
    cachedCodes.push(result.codeResult.code);
    if (cachedCodes.length <= 20)
      return;
    const modeOfCodes = getModeOfCodeErrors(cachedCodes);
    let zeroPaddedCode = modeOfCodes.padStart(13, "0");
    codesRef.current = [];
    history.replace(`/results/${zeroPaddedCode}`);
    handleScannerClose();
  };
  const attachOrientationChangeListener = () => {
    if (portraitMediaQuery.addEventListener) {
      portraitMediaQuery.addEventListener("change", restartScanner);
    } else {
      portraitMediaQuery.addListener(restartScanner);
    }
  };
  const removeOrientationChangeListener = () => {
    if (portraitMediaQuery.removeEventListener) {
      portraitMediaQuery.removeEventListener("change", restartScanner);
    } else {
      portraitMediaQuery.removeListener(restartScanner);
    }
  };
  const updateZoom = (newZoomValue) => {
    const track = Quagga.CameraAccess.getActiveTrack();
    if (!track)
      return;
    track.applyConstraints({advanced: [{zoom: newZoomValue}]});
  };
  const toggleTorch = () => {
    const track = Quagga.CameraAccess.getActiveTrack();
    if (!track)
      return;
    track.applyConstraints({advanced: [{torch: !torchSettings.enabled}]});
    setTorchSettings((prev) => ({...prev, enabled: !prev.enabled}));
  };
  const handleZoomSliderChange = async (e) => {
    const newZoomValue = e.target.value;
    setZoomSettings((prev) => ({...prev, value: newZoomValue}));
    updateZoom(newZoomValue);
  };
  useEffect(() => {
    if (isScannerOpen) {
      if (scannerRef && scannerRef.current) {
        attachOrientationChangeListener();
        initScanner();
      }
    } else {
      removeOrientationChangeListener();
      stopScanner();
    }
    return () => {
      removeOrientationChangeListener();
      stopScanner();
    };
  }, [isScannerOpen, scannerRef]);
  return /* @__PURE__ */ React.createElement("div", {
    id: "scanner-ref",
    ref: scannerRef,
    style: {display: isScannerOpen ? "block" : "none"}
  }, /* @__PURE__ */ React.createElement("p", null, isPaused ? "Scanning is paused." : "Make sure the bar code is within the frame."), /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center z-50 text-white bottom-6 left-8 right-8 absolute"
  }, zoomSettings !== null && /* @__PURE__ */ React.createElement("input", {
    className: "zoom-slider w-40",
    type: "range",
    value: zoomSettings.value ?? 0,
    min: zoomSettings.min,
    max: zoomSettings.max,
    min: zoomSettings.min,
    step: zoomSettings.step,
    style: {
      backgroundSize: (zoomSettings.value - zoomSettings.min) * 100 / (zoomSettings.max - zoomSettings.min) + "% 100%"
    },
    onChange: handleZoomSliderChange
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex gap-4 ml-auto"
  }, torchSettings !== null && /* @__PURE__ */ React.createElement("button", {
    className: "flex w-10 items-center justify-center rounded-full border-4 border-gray-200 bg-primary",
    onClick: toggleTorch
  }, torchSettings.enabled ? /* @__PURE__ */ React.createElement(LightningBoltOutlineIcon, {
    className: "h-5 text-gray-200"
  }) : /* @__PURE__ */ React.createElement(LightningBoltSolidIcon, {
    className: "h-5 text-gray-200"
  })), /* @__PURE__ */ React.createElement("button", {
    className: "rounded-full bg-gray-200",
    onClick: toggleScannerPause
  }, isPaused ? /* @__PURE__ */ React.createElement(PlayIcon, {
    className: "h-10 text-primary"
  }) : /* @__PURE__ */ React.createElement(PauseIcon, {
    className: "h-10 text-primary"
  })), /* @__PURE__ */ React.createElement("button", {
    className: "rounded-full bg-gray-200",
    onClick: handleScannerClose
  }, /* @__PURE__ */ React.createElement(StopIcon, {
    className: "h-10 text-primary"
  })))));
};
export default BarcodeScanner;
