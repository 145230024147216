import React, {useRef, useState, useEffect, Fragment} from "../../../../../_snowpack/pkg/react.js";
import {useParams, useHistory} from "../../../../../_snowpack/pkg/react-router.js";
import {Dialog, Transition} from "../../../../../_snowpack/pkg/@headlessui/react.js";
import SubtractFillIcon from "../../../../../_snowpack/pkg/remixicon-react/SubtractFillIcon.js";
import AddFillIcon from "../../../../../_snowpack/pkg/remixicon-react/AddFillIcon.js";
import CurrencyInput from "../../../../../_snowpack/pkg/react-currency-input-field.js";
import axios from "../../../../../_snowpack/pkg/axios.js";
import {Link} from "../../../../../_snowpack/pkg/react-router-dom.js";
import {DateTime} from "../../../../../_snowpack/pkg/luxon.js";
import TaxCodeInput from "../../../tax-code-input/index.js";
import {generateAmazonImageUrl} from "../../../../utils/generate-amazon-image-url.js";
const EditBuyListItemModal = ({buyListItem, onSuccess}) => {
  if (!buyListItem)
    return null;
  const [form, updateForm] = useState({
    qty: buyListItem.qty,
    expirationDate: DateTime.fromISO(buyListItem.expirationDate).toISODate(),
    taxCode: buyListItem.taxCode,
    condition: buyListItem.condition,
    costPrice: buyListItem.costPrice
  });
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams();
  const {qty, expirationDate, taxCode, condition, costPrice} = form;
  const history = useHistory();
  const qtyInputRef = useRef(null);
  const editBuyListItem = async (formData) => {
    const {buyListId, buyListItemId} = params;
    await axios.put(`/api/v1/buy-list/${buyListId}/${buyListItemId}`, formData);
    onSuccess();
    history.push(`/buy-lists/${buyListId}`);
  };
  const handleEditBuyListItem = (event) => {
    event.preventDefault();
    editBuyListItem({...form, asin: buyListItem.asin});
  };
  const onInputChange = (event) => {
    let {name, value} = event.target;
    if (name === "qty")
      value = Number(value);
    updateForm({...form, [name]: value});
  };
  const incrementQty = () => {
    updateForm({...form, qty: Number(qty) + 1});
  };
  const decrementQty = () => {
    const oneDecreasedQty = Number(qty) - 1;
    const newQty = oneDecreasedQty < 0 ? "" : oneDecreasedQty;
    updateForm({...form, qty: newQty});
  };
  const handleFocus = (event) => {
    event.target.scrollIntoView({behavior: "smooth", block: "nearest"});
    event.target.select();
  };
  const handleCostChange = (newCost) => updateForm({...form, costPrice: newCost ? newCost : ""});
  const handleTaxCodeChange = (newTaxCode) => updateForm({...form, taxCode: newTaxCode});
  const handleQtyBackspace = (event) => {
    if (event.key === "Backspace" && qtyInputRef.current.value.length === 1) {
      updateForm({...form, qty: ""});
    }
  };
  const handleCloseModal = () => {
    setIsOpen(false);
    history.push(`/buy-lists/${params.buyListId}`);
  };
  useEffect(() => {
    updateForm({...form, asin: buyListItem.asin});
  }, [buyListItem.asin]);
  useEffect(() => {
    if (params?.buyListItemId) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [params?.buyListItemId]);
  return /* @__PURE__ */ React.createElement(Transition.Root, {
    show: isOpen,
    as: Fragment
  }, /* @__PURE__ */ React.createElement(Dialog, {
    as: "div",
    static: true,
    className: "fixed inset-0 overflow-hidden",
    open: isOpen,
    onClose: handleCloseModal
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 overflow-hidden"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-in-out duration-500",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "ease-in-out duration-500",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement(Dialog.Overlay, {
    className: "absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "fixed inset-y-0 left-0 max-w-full flex"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "transform transition ease-in-out duration-500 sm:duration-700",
    enterFrom: "-translate-x-full",
    enterTo: "translate-x-0",
    leave: "transform transition ease-in-out duration-500 sm:duration-700",
    leaveFrom: "translate-x-full",
    leaveTo: "-translate-x-full"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-screen max-w-md"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "h-full flex flex-col py-6 bg-white shadow-xl"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-start justify-between"
  }, /* @__PURE__ */ React.createElement(Dialog.Title, {
    className: "text-xl font-bold text-gray-900"
  }, "Edit Buy List Item"))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-6 relative flex-1 px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col h-full border-t-2 border-gray-200",
    "aria-hidden": "true"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex mt-4"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "w-16 mr-2",
    src: generateAmazonImageUrl(buyListItem.image, 96)
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-sm line-clamp-2"
  }, buyListItem.title), /* @__PURE__ */ React.createElement("p", {
    className: "text-xs text-gray-500 mt-1"
  }, "ASIN: ", buyListItem.asin))), /* @__PURE__ */ React.createElement("form", {
    className: "flex flex-col mt-4 flex-grow",
    onSubmit: handleEditBuyListItem
  }, /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "QTY", /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "qty",
    type: "number",
    className: "order-2 border-none w-11 text-center mx-1 px-0 text-gray-500",
    onInput: onInputChange,
    onKeyDown: handleQtyBackspace,
    onFocus: handleFocus,
    value: qty,
    min: 1,
    ref: qtyInputRef,
    required: true
  }), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "order-1 text-white bg-gray-600 rounded-full p-1",
    onClick: decrementQty
  }, /* @__PURE__ */ React.createElement(SubtractFillIcon, {
    size: 18
  })), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "order-3 text-white bg-gray-600 rounded-full p-1",
    onClick: incrementQty
  }, /* @__PURE__ */ React.createElement(AddFillIcon, {
    size: 18
  })))), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "Expiration Date", /* @__PURE__ */ React.createElement("input", {
    name: "expirationDate",
    type: "date",
    className: "flex flex-row-reverse appearance-none pr-0 border-none text-right text-gray-500",
    onInput: onInputChange,
    value: expirationDate
  })), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "Tax Code", /* @__PURE__ */ React.createElement(TaxCodeInput, {
    selectedTaxCode: taxCode,
    setTaxCode: handleTaxCodeChange
  })), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "Cost", /* @__PURE__ */ React.createElement(CurrencyInput, {
    name: "costPrice",
    className: "border-none text-right px-0 text-gray-500",
    prefix: "$",
    value: costPrice,
    onValueChange: handleCostChange,
    placeholder: "",
    required: true,
    onFocus: handleFocus
  })), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "Condition", /* @__PURE__ */ React.createElement("select", {
    name: "condition",
    className: "border-none bg-right pr-7 -mr-1",
    value: condition,
    onChange: onInputChange
  }, /* @__PURE__ */ React.createElement("option", {
    value: "New"
  }, "New"), /* @__PURE__ */ React.createElement("option", {
    value: "Old"
  }, "Old"))), /* @__PURE__ */ React.createElement(Link, {
    to: `/products/${buyListItem.asin}`,
    className: "text-primary text-center mt-8 font-medium"
  }, "See Product Details"), /* @__PURE__ */ React.createElement("div", {
    className: "flex gap-4 w-full justify-between mt-auto mb-4"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "text-gray-800 bg-gray-300 flex-1 p-2 rounded font-medium",
    onClick: handleCloseModal
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "text-white bg-primary flex-1 p-2 rounded font-medium"
  }, "Save")))))))))))));
};
export default EditBuyListItemModal;
