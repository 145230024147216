import React from "../../../_snowpack/pkg/react.js";
import {Switch, Route} from "../../../_snowpack/pkg/react-router-dom.js";
import DashboardSearch from "../dashboard/dashboard-search.js";
import ScanResults from "../scan-results/index.js";
import ProductDetail from "../product-detail/index.js";
import BuyLists from "../buy-lists/index.js";
import BuyList from "../buy-list/index.js";
const Content = ({isDashboardUser, counter}) => {
  return /* @__PURE__ */ React.createElement("main", {
    style: {gridArea: "main", overflow: "auto"}
  }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/dashboard",
    component: DashboardSearch
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/results/:barcode",
    component: ScanResults
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/products/:barcode",
    render: (props) => /* @__PURE__ */ React.createElement(ProductDetail, {
      ...props,
      counter
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    path: ["/buy-lists/:buyListId/:buyListItemId", "/buy-lists/:buyListId"],
    render: (p) => /* @__PURE__ */ React.createElement(BuyList, {
      ...p,
      isDashboardUser
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/buy-lists",
    component: BuyLists
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/archive-buy-lists",
    component: BuyLists
  })));
};
export default Content;
