// Detecting iOS <=12 and 13+ https://stackoverflow.com/a/64207488/6943553
export default function isDeviceAnIOS() {
  const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform)
  const iOS13_iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  const iOS1to12quirk = function() {
    var audio = new Audio() // temporary Audio object
    audio.volume = 0.5 // has no effect on iOS <= 12
    return audio.volume === 1
  }
  // `!window.MSStream` to avoid detecting IE on Windows Mobile
  const isIOS = !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk())

  return isIOS
}