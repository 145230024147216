import React from "../../../_snowpack/pkg/react.js";
const SearchInput = (props) => {
  const {value, onChange, placeholder} = props;
  return /* @__PURE__ */ React.createElement("input", {
    className: "w-full rounded-full py-2 px-5 outline-none bg-gray-100",
    placeholder,
    onChange,
    value
  });
};
export default SearchInput;
