import React, {useState, useEffect, useRef} from "../../../../../_snowpack/pkg/react.js";
import {XIcon, ChevronDownIcon} from "../../../../../_snowpack/pkg/@heroicons/react/solid.js";
import axios from "../../../../../_snowpack/pkg/axios.js";
const BuyListSelect = ({selectedBuyListId, setBuyListId, buyLists}) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const searchBarRef = useRef(null);
  const selectedBuyListIdRef = useRef(null);
  const resultsContainerRef = useRef(null);
  const buyListsFilter = ({name}) => {
    const normalizedSearch = search.toLowerCase().trim();
    const nameIncludesSearch = name.toLowerCase().includes(normalizedSearch);
    return nameIncludesSearch;
  };
  const handleBuyListClick = (newBuyListId) => (event) => {
    event.preventDefault();
    setIsOpen(false);
    setBuyListId(newBuyListId);
  };
  const handleBuyListSelectOpen = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };
  useEffect(() => {
    if (isOpen) {
      setSearch("");
      searchBarRef.current.focus();
      selectedBuyListIdRef?.current?.scrollIntoView();
    }
  }, [isOpen]);
  const selectedBuyListName = buyLists.find(({_id}) => _id === selectedBuyListId)?.name ?? "";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between p-2 h-11 w-full border border-gray-400 items-center rounded font-normal",
    onClick: handleBuyListSelectOpen
  }, selectedBuyListId ? selectedBuyListName : /* @__PURE__ */ React.createElement("span", {
    className: "text-gray-500"
  }, "Choose from list..."), /* @__PURE__ */ React.createElement(ChevronDownIcon, {
    className: "w-5 h-5 text-primary"
  })), /* @__PURE__ */ React.createElement("input", {
    name: "buyListId",
    type: "text",
    className: "flex-grow text-right border-none p-0 text-gray-500",
    value: selectedBuyListId,
    readOnly: true,
    hidden: true
  }), /* @__PURE__ */ React.createElement("div", {
    className: `flex flex-col fixed inset-0 bg-white text-gray-800 z-30 transition-transform transform duration-300 ${isOpen ? "translate-x-0" : "-translate-x-full"}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "mx-5 mt-5"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-row justify-between items-center mb-3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "text-xl font-semibold"
  }, "Select Buy List"), /* @__PURE__ */ React.createElement("button", {
    className: "",
    onClick: () => setIsOpen(false)
  }, /* @__PURE__ */ React.createElement(XIcon, {
    className: "h-6"
  }))), /* @__PURE__ */ React.createElement("input", {
    type: "search",
    className: "rounded-full text-sm w-full border-2 focus:border-primary-300 focus:ring-primary-300",
    placeholder: "Search for the buy list name",
    ref: searchBarRef,
    value: search,
    onInput: (e) => setSearch(e.target.value)
  })), /* @__PURE__ */ React.createElement("div", {
    className: "my-2 px-4 overflow-auto ",
    ref: resultsContainerRef
  }, buyLists.filter(buyListsFilter).map(({_id, name}, index) => {
    const isSelected = _id === selectedBuyListId;
    return /* @__PURE__ */ React.createElement("div", {
      className: "flex py-3 px-1 font-medium text-lg",
      onClick: handleBuyListClick(_id),
      ref: isSelected ? selectedBuyListIdRef : null,
      key: index
    }, name);
  }))));
};
export default BuyListSelect;
