import React, {useState} from "../../../../../_snowpack/pkg/react.js";
import ArrowUpDownFillIcon from "../../../../../_snowpack/pkg/remixicon-react/ArrowUpDownFillIcon.js";
const ToggleSwitch = ({show}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: `relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ${show ? "bg-primary-400" : "bg-gray-200"}`
  }, /* @__PURE__ */ React.createElement("span", {
    className: `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${show ? "translate-x-5" : "translate-x-0"}`
  }));
};
const settingsListItem = ({func, provided, index, setFunctionalities, startDrag, selectedRow}) => {
  const [show, setShow] = useState(func.show);
  const handleClickFunc = () => {
    setShow((old) => !old);
    func.show = !func.show;
    setFunctionalities((old) => {
      let oldUnique = old.filter((ol) => ol.title != func.title);
      oldUnique.splice(index, 0, func);
      return oldUnique;
    });
  };
  return /* @__PURE__ */ React.createElement("li", {
    className: `p-2 rounded-sm bg-gray-50 ${show ? "text-gray-700" : "text-gray-300"} ${show ? "text-black" : "text-white"} flex justify-between`,
    ...provided.draggableProps,
    ...provided.dragHandleProps,
    ref: provided.innerRef,
    onClick: handleClickFunc
  }, /* @__PURE__ */ React.createElement("p", null, func.title), startDrag ? index === selectedRow ? /* @__PURE__ */ React.createElement(ArrowUpDownFillIcon, {
    color: "#999"
  }) : /* @__PURE__ */ React.createElement(ToggleSwitch, {
    show
  }) : /* @__PURE__ */ React.createElement(ToggleSwitch, {
    show
  }));
};
export default settingsListItem;
