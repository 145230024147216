import { r as react } from './common/index-04edb6a1.js';
import './common/_commonjsHelpers-8c19dec8.js';

var DEFAULT_ROOT_MARGIN = '0px';
var DEFAULT_THRESHOLD = [0]; // For more info:
// https://developers.google.com/web/updates/2016/04/intersectionobserver
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

function useIntersectionObserver(args) {
  var _args$rootMargin, _args$threshold;

  var rootMargin = (_args$rootMargin = args == null ? void 0 : args.rootMargin) != null ? _args$rootMargin : DEFAULT_ROOT_MARGIN;
  var threshold = (_args$threshold = args == null ? void 0 : args.threshold) != null ? _args$threshold : DEFAULT_THRESHOLD;
  var nodeRef = react.useRef(null);
  var rootRef = react.useRef(null);
  var observerRef = react.useRef(null);

  var _useState = react.useState(),
      entry = _useState[0],
      setEntry = _useState[1];

  var unobserve = react.useCallback(function () {
    // Disconnect the current observer (if there is one)
    var currentObserver = observerRef.current;
    currentObserver == null ? void 0 : currentObserver.disconnect();
    observerRef.current = null;
  }, []);
  react.useEffect(function () {
    return function () {
      // We disconnect the observer on unmount to prevent memory leaks etc.
      unobserve();
    };
  }, [unobserve]);
  var observe = react.useCallback(function () {
    var node = nodeRef.current;

    if (node) {
      var root = rootRef.current;
      var options = {
        root: root,
        rootMargin: rootMargin,
        threshold: threshold
      }; // Create a observer for current "node" with given options.

      var observer = new IntersectionObserver(function (_ref) {
        var newEntry = _ref[0];
        setEntry(newEntry);
      }, options);
      observer.observe(node);
      observerRef.current = observer;
    }
  }, [rootMargin, threshold]);
  var initializeObserver = react.useCallback(function () {
    unobserve();
    observe();
  }, [observe, unobserve]);
  var refCallback = react.useCallback(function (node) {
    nodeRef.current = node;
    initializeObserver();
  }, [initializeObserver]);
  var rootRefCallback = react.useCallback(function (rootNode) {
    rootRef.current = rootNode;
    initializeObserver();
  }, [initializeObserver]);
  return [refCallback, {
    entry: entry,
    rootRef: rootRefCallback
  }];
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function useTrackVisibility(args) {
  var _result$entry;

  var _useIntersectionObser = useIntersectionObserver(args),
      ref = _useIntersectionObser[0],
      result = _useIntersectionObser[1];

  var isVisible = Boolean((_result$entry = result.entry) == null ? void 0 : _result$entry.isIntersecting);

  var _useState = react.useState(isVisible),
      wasEverVisible = _useState[0],
      setWasEverVisible = _useState[1];

  react.useEffect(function () {
    if (isVisible) {
      setWasEverVisible(isVisible);
    }
  }, [isVisible]);
  return [ref, _extends({}, result, {
    isVisible: isVisible,
    wasEverVisible: wasEverVisible
  })];
}

var DEFAULT_DELAY_IN_MS = 100;

function useInfiniteScroll(_ref) {
  var loading = _ref.loading,
      hasNextPage = _ref.hasNextPage,
      onLoadMore = _ref.onLoadMore,
      rootMargin = _ref.rootMargin,
      disabled = _ref.disabled,
      _ref$delayInMs = _ref.delayInMs,
      delayInMs = _ref$delayInMs === void 0 ? DEFAULT_DELAY_IN_MS : _ref$delayInMs;

  var _useTrackVisibility = useTrackVisibility({
    rootMargin: rootMargin
  }),
      ref = _useTrackVisibility[0],
      _useTrackVisibility$ = _useTrackVisibility[1],
      rootRef = _useTrackVisibility$.rootRef,
      isVisible = _useTrackVisibility$.isVisible;

  var shouldLoadMore = !disabled && !loading && isVisible && hasNextPage; // eslint-disable-next-line consistent-return

  react.useEffect(function () {
    if (shouldLoadMore) {
      // When we trigger 'onLoadMore' and new items are added to the list,
      // right before they become rendered on the screen, 'loading' becomes false
      // and 'isVisible' can be true for a brief time, based on the scroll position.
      // So, it triggers 'onLoadMore' just after the first one is finished.
      // We use a small delay here to prevent this kind of situations.
      // It can be configured by hook args.
      var timer = setTimeout(function () {
        onLoadMore();
      }, delayInMs);
      return function () {
        clearTimeout(timer);
      };
    }
  }, [onLoadMore, shouldLoadMore, delayInMs]);
  return [ref, {
    rootRef: rootRef
  }];
}

export default useInfiniteScroll;
