import React from "../../../../../_snowpack/pkg/react.js";
const ReplenDashboardIcon = () => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "74",
    height: "74",
    viewBox: "0 0 74 74",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("g", {
    filter: "url(#filter0_d_1590_5632)"
  }, /* @__PURE__ */ React.createElement("circle", {
    cx: "37",
    cy: "36",
    r: "25",
    fill: "#F93402"
  })), /* @__PURE__ */ React.createElement("path", {
    d: "M36.7833 30.0679C44.5812 30.0679 51.1536 35.2665 53.2331 42.396C52.3295 34.1154 45.3114 27.6667 36.7833 27.6667C28.2551 27.6667 21.2371 34.103 20.3335 42.3836C22.4129 35.2665 28.9978 30.0679 36.7833 30.0679Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M42.2293 34.5857C42.3159 34.462 42.3778 34.103 42.2293 33.9916C42.0807 33.8802 41.7589 34.0535 41.6599 34.1773L36.4984 40.626C36.4365 40.6136 36.3747 40.6136 36.3004 40.6136C35.4959 40.6136 34.8398 41.2696 34.8398 42.0742C34.8398 42.8787 35.4959 43.5347 36.3004 43.5347C37.1049 43.5347 37.761 42.8787 37.761 42.0742C37.761 41.8637 37.7114 41.6657 37.6372 41.48L42.2293 34.5857Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", {
    id: "filter0_d_1590_5632",
    x: "0",
    y: "0",
    width: "74",
    height: "74",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ React.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ React.createElement("feMorphology", {
    radius: "2",
    operator: "dilate",
    in: "SourceAlpha",
    result: "effect1_dropShadow_1590_5632"
  }), /* @__PURE__ */ React.createElement("feOffset", {
    dy: "1"
  }), /* @__PURE__ */ React.createElement("feGaussianBlur", {
    stdDeviation: "5"
  }), /* @__PURE__ */ React.createElement("feComposite", {
    in2: "hardAlpha",
    operator: "out"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_1590_5632"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect1_dropShadow_1590_5632",
    result: "shape"
  }))));
};
export default ReplenDashboardIcon;
