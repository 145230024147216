import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

undefined /* [snowpack] import.meta.hot */ ;
import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {Switch, Route} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
import Spinner from "../spinner/index.js";
import OfflinePage from "../offline-page/index.js";
import LoggedInContainer from "../logged-in-container/index.js";
import LoggedOutContainer from "../logged-out-container/index.js";
import isDeviceAnIOS from "../../utils/isDeviceAnIOS.js";
import "./app.css";
function App() {
  const [user, setUser] = useState(null);
  const [isWelcomeShown, setIsWelcomeShown] = useState(false);
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const [isCheckUserFailed, setIsCheckUserFailed] = useState(false);
  const updateViewportHeightVar = () => {
    const rootElementStyle = document.querySelector("html").style;
    const height = window.visualViewport ? window.visualViewport.height : window.innerHeight;
    rootElementStyle.setProperty("--viewport-height", height + "px");
  };
  const setAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
  };
  const unsetAuthorization = () => {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("loginToken");
  };
  const handleLogout = () => {
    unsetAuthorization();
    setUser(null);
  };
  const handleLoginSuccess = (loginToken, user2) => {
    localStorage.setItem("loginToken", loginToken);
    setAuthorization(loginToken);
    setIsWelcomeShown(true);
    setUser(user2);
  };
  const setDefaultHeaders = () => {
    axios.defaults.baseURL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_SCOUT_APP_API_URL;
    axios.defaults.headers.get["Content-Type"] = "application/json";
    axios.defaults.headers.post["Content-Type"] = "application/json";
  };
  const checkUser = async () => {
    const loginToken = localStorage.getItem("loginToken");
    try {
      if (loginToken) {
        setAuthorization(loginToken);
        const response = await axios.get("/api/v1/user");
        if (response.status === 200) {
          const {user: user2} = response.data;
          setUser(user2);
          setIsCheckUserFailed(false);
        }
      }
    } catch (error) {
      switch (error?.response?.status) {
        case 400:
          unsetAuthorization();
          break;
        default:
          setIsCheckUserFailed(true);
          addOnlineListener();
      }
    } finally {
      setIsTokenChecked(true);
    }
  };
  const handleReconnect = () => {
    setIsTokenChecked(false);
    checkUser();
  };
  const addOnlineListener = () => {
    window.addEventListener("online", handleReconnect, {once: true});
  };
  const toggleIsWelcomeShown = () => setIsWelcomeShown((prev) => !prev);
  useEffect(() => {
    setDefaultHeaders();
    checkUser();
    if (isDeviceAnIOS()) {
      const viewportMetaTag = document.querySelector("meta[name=viewport]");
      viewportMetaTag.content += ", maximum-scale=1.0";
    }
    void function updateLoop() {
      updateViewportHeightVar();
      setTimeout(() => updateLoop(), 100);
    }();
    window.addEventListener("scroll", () => window.scrollTo(0, 0));
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "app md:container md:mx-auto"
  }, isTokenChecked ? isCheckUserFailed ? /* @__PURE__ */ React.createElement(OfflinePage, {
    handleReconnect
  }) : /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/auth",
    render: (p) => /* @__PURE__ */ React.createElement(LoggedOutContainer, {
      ...p,
      user,
      handleLoginSuccess
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/",
    render: (p) => /* @__PURE__ */ React.createElement(LoggedInContainer, {
      ...p,
      user,
      handleLogout,
      isWelcomeShown,
      toggleIsWelcomeShown
    })
  })) : localStorage.loginToken ? /* @__PURE__ */ React.createElement(Spinner, {
    className: "h-32 w-auto m-auto text-primary"
  }) : null);
}
export default App;
