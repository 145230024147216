import React, {useState, useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import {XIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
import {taxCodes, sections} from "./tax-code-table.js";
const TaxCodeInput = ({selectedTaxCode, setTaxCode}) => {
  const [search, setSearch] = useState(selectedTaxCode);
  const [isOpen, setIsOpen] = useState(false);
  const searchBarRef = useRef(null);
  const selectedTaxCodeRef = useRef(null);
  const resultsContainerRef = useRef(null);
  const normalizedSearch = search.toLowerCase().trim();
  const taxCodeFilter = (taxCode) => {
    const taxCodeIncludesSearch = taxCode.toLowerCase().includes(normalizedSearch);
    const descriptionIncludesSearch = taxCodes[taxCode].toLowerCase().includes(normalizedSearch);
    return taxCodeIncludesSearch || descriptionIncludesSearch;
  };
  const handleTaxCodeClick = (newTaxCode) => (event) => {
    event.preventDefault();
    setIsOpen(false);
    setTaxCode(newTaxCode);
  };
  const handleTaxCodeInputClick = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };
  useEffect(() => {
    if (isOpen) {
      setSearch("");
      searchBarRef.current.focus();
      selectedTaxCodeRef.current.scrollIntoView();
    }
  }, [isOpen]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("input", {
    name: "taxCode",
    type: "text",
    className: "flex-grow text-right border-none p-0 text-gray-500",
    value: selectedTaxCode,
    onClick: handleTaxCodeInputClick,
    readOnly: true
  }), /* @__PURE__ */ React.createElement("div", {
    className: `flex flex-col fixed inset-0 bg-white text-gray-800 z-30 transition-transform transform duration-300 ${isOpen ? "translate-x-0" : "-translate-x-full"}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "mx-5 mt-5"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-row justify-between items-center mb-3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "text-xl font-semibold"
  }, "Search Tax Code"), /* @__PURE__ */ React.createElement("button", {
    className: "",
    onClick: () => setIsOpen(false)
  }, /* @__PURE__ */ React.createElement(XIcon, {
    className: "h-6"
  }))), /* @__PURE__ */ React.createElement("input", {
    type: "search",
    className: "rounded-full text-sm w-full border-2 focus:border-primary-300 focus:ring-primary-300",
    placeholder: "Input the tax code (or its description / section)",
    ref: searchBarRef,
    value: search,
    onInput: (e) => setSearch(e.target.value)
  })), /* @__PURE__ */ React.createElement("div", {
    className: "my-2 px-4 overflow-auto ",
    ref: resultsContainerRef
  }, Object.entries(sections).map(([section, sectionTaxCodes]) => {
    const doesTitleIncludeSearch = section.toLowerCase().includes(normalizedSearch);
    const filteredTaxCodes = sectionTaxCodes.filter(taxCodeFilter);
    return doesTitleIncludeSearch || filteredTaxCodes.length ? /* @__PURE__ */ React.createElement("div", {
      className: "mt-3",
      key: section
    }, /* @__PURE__ */ React.createElement("div", {
      className: "font-semibold"
    }, section), (filteredTaxCodes.length ? filteredTaxCodes : sectionTaxCodes).map((taxCode) => {
      const isSelected = taxCode === selectedTaxCode;
      const taxCodeClass = `flex flex-row items-center rounded-md h-11 p-1 ${isSelected ? " bg-primary-200" : ""}`;
      return /* @__PURE__ */ React.createElement("div", {
        className: taxCodeClass,
        onClick: handleTaxCodeClick(taxCode),
        key: taxCode,
        ref: isSelected ? selectedTaxCodeRef : null
      }, /* @__PURE__ */ React.createElement("span", {
        className: "text-xs font-medium mx-2"
      }, taxCode), /* @__PURE__ */ React.createElement("span", {
        className: "text-xxs leading-tight"
      }, taxCodes[taxCode]));
    })) : null;
  }))));
};
export default TaxCodeInput;
