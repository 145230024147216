import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {Link, useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
import {ArchiveIcon, PencilIcon, ChevronRightIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
import {ArchiveIcon as UnArchiveIcon} from "../../../_snowpack/pkg/@heroicons/react/outline.js";
import SearchInput from "../search-input/index.js";
import CreateBuyListButton from "../create-buy-list-button/index.js";
import EditBuyListNameModal from "./components/edit-buy-list-name-modal/index.js";
const BuyLists = () => {
  const [buyLists, setBuyLists] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [buyListToEdit, setBuyListToEdit] = useState(null);
  const [isArchiveList, setIsArchiveList] = useState(false);
  const location = useLocation();
  const getBuyLists = async () => {
    setHasError(false);
    setIsLoading(true);
    try {
      const response = await axios.get("/api/v1/buy-list");
      setBuyLists(response.data);
    } catch (err) {
      setHasError(true);
    }
    setIsLoading(false);
  };
  const getArchiveLists = async () => {
    setHasError(false);
    setIsLoading(true);
    try {
      const response = await axios.get("/api/v1/archive-buy-lists");
      setBuyLists(response.data);
    } catch (err) {
      setHasError(true);
    }
    setIsLoading(false);
  };
  const handleEditClick = (buyListId) => () => {
    setBuyListToEdit(buyListId);
    setIsEditOpen(true);
  };
  const handleArchiveClick = (buyListId) => () => {
    axios.post(`/api/v1/archive-buy-lists/${buyListId}`, {archive: !isArchiveList}).then(() => {
      if (isArchiveList) {
        getArchiveLists();
      } else {
        getBuyLists();
      }
    }).catch((err) => {
      console.log(err);
    });
  };
  useEffect(() => {
    if (location && location.pathname) {
      if (location.pathname === "/archive-buy-lists") {
        setIsArchiveList(true);
        getArchiveLists();
      } else {
        setIsArchiveList(false);
        getBuyLists();
      }
    }
  }, [location]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "w-full h-full bg-white"
  }, isArchiveList ? /* @__PURE__ */ React.createElement("div", {
    className: "sm:flex p-4 text-gray-800 text-sm text-lg"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "w-1/3 p-1 font-semibold"
  }, "Archived Lists")) : /* @__PURE__ */ React.createElement("div", {
    className: "flex sm:flex justify-between sm:justify-between p-4 text-gray-800 text-sm text-lg"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "w-4/12 p-1 font-semibold"
  }, "Buy Lists"), /* @__PURE__ */ React.createElement("div", {
    className: "flex sm:flex justify-between sm:justify-between sm:w-8/12 py-1"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/archive-buy-lists",
    className: "relative text-primary text-sm text-center border border-red-200 pl-5 pr-1 py-1 mr-1"
  }, /* @__PURE__ */ React.createElement(ArchiveIcon, {
    className: "w-4 absolute left-0.5 top-1.5"
  }), "View Archived"), /* @__PURE__ */ React.createElement(CreateBuyListButton, {
    onSuccess: () => getBuyLists()
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "px-4"
  }, /* @__PURE__ */ React.createElement(SearchInput, {
    value: textFilter,
    onChange: (e) => setTextFilter(e.target.value),
    placeholder: "Filter buy lists..."
  })), isLoading ? /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-center"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "my-10"
  }, "Loading...")) : hasError ? /* @__PURE__ */ React.createElement("div", {
    className: "flex my-10 mx-4 justify-center text-center"
  }, "Something went wrong while ", /* @__PURE__ */ React.createElement("br", null), " loading your", " ", isArchiveList ? "archive" : "buy", " list.") : /* @__PURE__ */ React.createElement("div", {
    className: "mx-5 pt-4"
  }, buyLists.length > 0 ? React.Children.toArray(buyLists.map(({_id, name}) => /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between items-center border p-2"
  }, /* @__PURE__ */ React.createElement("span", null, name), /* @__PURE__ */ React.createElement("div", {
    className: "flex-grow flex gap-4 justify-end text-gray-700"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: handleArchiveClick(_id)
  }, isArchiveList ? /* @__PURE__ */ React.createElement(UnArchiveIcon, {
    className: "w-5 h-5"
  }) : /* @__PURE__ */ React.createElement(ArchiveIcon, {
    className: "w-5 h-5"
  })), /* @__PURE__ */ React.createElement("button", {
    onClick: handleEditClick(_id)
  }, /* @__PURE__ */ React.createElement(PencilIcon, {
    className: "w-5 h-5"
  })), /* @__PURE__ */ React.createElement(Link, {
    to: `/buy-lists/${_id}`
  }, /* @__PURE__ */ React.createElement(ChevronRightIcon, {
    className: "w-8 h-8 -ml-2 -mr-2"
  })))))) : /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-center"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "my-10 italic"
  }, "Your ", isArchiveList ? "archive" : "buy", " list is empty."))), /* @__PURE__ */ React.createElement(EditBuyListNameModal, {
    isOpen: isEditOpen,
    setIsOpen: setIsEditOpen,
    buyListId: buyListToEdit,
    onSuccess: () => isArchiveList ? getArchiveLists() : getBuyLists()
  }));
};
export default BuyLists;
