import React from "../../../_snowpack/pkg/react.js";
import axios from "../../../_snowpack/pkg/axios.js";
function useSearchResult(barcode) {
  const [loading, isLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [error, setError] = React.useState();
  const [pageIndex, setPageIndex] = React.useState(0);
  const [historyText, setHistoryText] = React.useState(barcode);
  const isValidBarcode = (string) => {
    const reducer = (p, v, i) => i % 2 === 0 ? p + 1 * v : p + 3 * v;
    const checksum = string.split("").reduce(reducer, 0);
    return checksum % 10 === 0;
  };
  const filterKeyword = (keyword) => {
    let keywordCopy = keyword;
    if (keywordCopy.includes("%25")) {
      keywordCopy = keywordCopy.replaceAll("%25", "%");
    }
    if (keywordCopy.includes("% ")) {
      keywordCopy = keywordCopy.replaceAll("% ", "%25 ");
    }
    return keywordCopy;
  };
  const loadMore = async () => {
    try {
      isLoading(true);
      const result = await axios.get(`/api/v1/products/${filterKeyword(barcode)}?page=${pageIndex}`);
      const count = result?.data?.length || 0;
      if (pageIndex == 0) {
        const keyword = isValidBarcode(barcode) ? result?.data[0]?.title : barcode;
        setHistoryText(keyword);
        if (count > 0) {
          await axios.post(`/api/v1/search-history`, {keyword, resultCount: count});
        } else {
          await axios.post(`/api/v1/search-history`, {keyword: barcode, resultCount: count});
        }
      } else {
        if (count > 0) {
          await axios.post(`/api/v1/search-history`, {keyword: historyText, resultCount: results.length + count});
        }
      }
      if (count >= 10) {
        if (pageIndex < 9) {
          setHasNextPage(true);
          setPageIndex(pageIndex + 1);
        } else {
          setHasNextPage(false);
        }
        setResults((current) => {
          let newResult = {};
          for (const item of current) {
            newResult[item.asin] = item;
          }
          for (const item of result.data) {
            newResult[item.asin] = item;
          }
          return Object.values(newResult);
        });
      } else if (count > 0) {
        setHasNextPage(false);
        setResults((current) => {
          let newResult = {};
          for (const item of current) {
            newResult[item.asin] = item;
          }
          for (const item of result.data) {
            newResult[item.asin] = item;
          }
          return Object.values(newResult);
        });
      } else {
        setHasNextPage(false);
      }
      setError();
    } catch (err) {
      console.log("Search result error: " + err);
      setError(err);
    } finally {
      isLoading(false);
    }
  };
  return {loading, results, hasNextPage, error, loadMore};
}
export default useSearchResult;
