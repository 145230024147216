import React from "../../../_snowpack/pkg/react.js";
import {Disclosure, Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import ArrowUpLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowUpLineIcon.js";
import ArrowDownLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowDownLineIcon.js";
import ArrowUpSLineIcon from "../../../_snowpack/pkg/remixicon-react/ArrowUpSLineIcon.js";
import ArrowPercent from "./arrow-percent.js";
import NumberFormat from "../../../_snowpack/pkg/react-number-format.js";
const CategoryPanel = (props) => {
  const {categories} = props;
  const current = categories[0];
  return /* @__PURE__ */ React.createElement(Disclosure, null, ({open}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Disclosure.Button, null, /* @__PURE__ */ React.createElement("div", {
    className: `${open ? "flex text-left pt-3" : "flex text-left py-3 border-b-2 border-gray-200"}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-2/6"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "text-sm"
  }, props.title)), /* @__PURE__ */ React.createElement("div", {
    className: "w-4/6 relative flex pr-5"
  }, current && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "w-2/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value truncate"
  }, current.name), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, "Name")), /* @__PURE__ */ React.createElement("div", {
    className: "w-1/3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-value"
  }, current.ranking || "-"), /* @__PURE__ */ React.createElement("div", {
    className: "header-stat-label"
  }, "Rank#"))), /* @__PURE__ */ React.createElement("div", {
    className: "absolute right-0 top-0"
  }, /* @__PURE__ */ React.createElement(ArrowUpSLineIcon, {
    size: "1.25rem",
    color: "red",
    className: open ? "ml-auto mr-0 transform rotate-180" : "ml-auto mr-0"
  }))))), /* @__PURE__ */ React.createElement(Disclosure.Panel, {
    className: "border-b-2 border-gray-200 pb-5"
  }, /* @__PURE__ */ React.createElement("div", null, categories && categories.length > 0 && /* @__PURE__ */ React.createElement("table", {
    className: "table table-fixed w-full border border-gray-300 shadow"
  }, /* @__PURE__ */ React.createElement("thead", {
    className: "bg-gray-200 text-left"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600 w-3/5"
  }, "Category"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600 w-1/5 text-center"
  }, "Products Offered"), /* @__PURE__ */ React.createElement("th", {
    className: "p-1.5 font-normal text-xs text-gray-600 w-1/5 text-center"
  }, "Ranking"))), /* @__PURE__ */ React.createElement("tbody", {
    className: "bg-white text-left"
  }, categories.map(({name, ranking, totalProduct}, index) => /* @__PURE__ */ React.createElement("tr", {
    key: `${name}-${index}`
  }, /* @__PURE__ */ React.createElement("td", {
    className: "px-2 py-3 text-xs text-gray-800"
  }, name, " ", index === 0 && /* @__PURE__ */ React.createElement("span", {
    className: "badge badge-gray"
  }, "Current")), /* @__PURE__ */ React.createElement("td", {
    className: "px-1 py-3 text-xs text-gray-800 text-center"
  }, /* @__PURE__ */ React.createElement(NumberFormat, {
    value: totalProduct,
    displayType: "text",
    thousandSeparator: true
  })), /* @__PURE__ */ React.createElement("td", {
    className: "px-1 py-3 text-sm text-gray-800 text-center"
  }, ranking && /* @__PURE__ */ React.createElement(React.Fragment, null, "#", /* @__PURE__ */ React.createElement(NumberFormat, {
    value: ranking,
    displayType: "text"
  })))))))))));
};
export default CategoryPanel;
