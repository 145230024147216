import React from "../../../../../_snowpack/pkg/react.js";
export default function AddToBuyListIcon() {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "74",
    height: "74",
    viewBox: "0 0 74 74",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("g", {
    filter: "url(#filter0_d_1616_6096)"
  }, /* @__PURE__ */ React.createElement("circle", {
    cx: "37",
    cy: "36",
    r: "25",
    fill: "#F93402"
  })), /* @__PURE__ */ React.createElement("g", {
    clipPath: "url(#clip0_1616_6096)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M46.722 37.3889H38.3887V45.7222H35.6109V37.3889H27.2776V34.6111H35.6109V26.2778H38.3887V34.6111H46.722V37.3889Z",
    fill: "white"
  })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", {
    id: "filter0_d_1616_6096",
    x: "0",
    y: "0",
    width: "74",
    height: "74",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ React.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ React.createElement("feMorphology", {
    radius: "2",
    operator: "dilate",
    in: "SourceAlpha",
    result: "effect1_dropShadow_1616_6096"
  }), /* @__PURE__ */ React.createElement("feOffset", {
    dy: "1"
  }), /* @__PURE__ */ React.createElement("feGaussianBlur", {
    stdDeviation: "5"
  }), /* @__PURE__ */ React.createElement("feComposite", {
    in2: "hardAlpha",
    operator: "out"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_1616_6096"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect1_dropShadow_1616_6096",
    result: "shape"
  })), /* @__PURE__ */ React.createElement("clipPath", {
    id: "clip0_1616_6096"
  }, /* @__PURE__ */ React.createElement("rect", {
    width: "33.3333",
    height: "33.3333",
    fill: "white",
    transform: "translate(20.3333 19.3333)"
  }))));
}
