import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {Switch, Route, Redirect, useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import Notif from "../notif/index.js";
import ScoutLogin from "../scout-login/index.js";
import DashboardLogin from "../dashboard-login/index.js";
import Register from "../register/index.js";
const LoggedOutContainer = ({user, handleLoginSuccess}) => {
  const history = useHistory();
  const [notifOptions, setNotifOptions] = useState({});
  const closeNotif = () => {
    setNotifOptions({...notifOptions, isOpen: false});
  };
  const openErrorNotif = (message) => {
    setNotifOptions({
      isOpen: true,
      message,
      type: "danger"
    });
  };
  const openSuccessNotif = (message) => {
    setNotifOptions({
      isOpen: true,
      message,
      type: "success"
    });
  };
  useEffect(() => {
    if (user)
      history.push("/");
  }, [history, user]);
  useEffect(() => {
    history.push("/auth/login/scout");
  }, [history]);
  return user ? /* @__PURE__ */ React.createElement(Redirect, {
    to: "/"
  }) : /* @__PURE__ */ React.createElement("div", {
    className: "min-h-screen bg-gray-50 flex flex-col justify-center py-4 sm:py-12 sm:px-6 lg:px-8"
  }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/auth/login/scout",
    render: (p) => /* @__PURE__ */ React.createElement(ScoutLogin, {
      ...p,
      handleLoginSuccess,
      openErrorNotif
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/auth/login/dashboard",
    render: (p) => /* @__PURE__ */ React.createElement(DashboardLogin, {
      ...p,
      handleLoginSuccess,
      openErrorNotif
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/auth/register",
    render: (p) => /* @__PURE__ */ React.createElement(Register, {
      ...p,
      handleLoginSuccess,
      openErrorNotif,
      openSuccessNotif
    })
  })), /* @__PURE__ */ React.createElement(Notif, {
    ...notifOptions,
    handleClose: closeNotif
  }));
};
export default LoggedOutContainer;
