import React from "../../../../../_snowpack/pkg/react.js";
import {ExclamationCircleIcon} from "../../../../../_snowpack/pkg/@heroicons/react/solid.js";
const SettingsInput = (props) => {
  const {label, error, className, errorClass, name, type = "text", options, ...otherInputProps} = props;
  const inputClass = className ?? `appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 ${error ? "pr-8" : ""}`;
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: name,
    className: "block text-sm font-medium text-gray-700"
  }, label), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1 relative"
  }, (type === "text" || type === "password") && /* @__PURE__ */ React.createElement("input", {
    name,
    type,
    className: inputClass,
    ...otherInputProps
  }), type === "textarea" && /* @__PURE__ */ React.createElement("textarea", {
    name,
    className: inputClass,
    ...otherInputProps
  }), type === "select" && /* @__PURE__ */ React.createElement("select", {
    name,
    className: inputClass,
    ...otherInputProps
  }, options.map((ii) => /* @__PURE__ */ React.createElement("option", {
    className: "font-medium py-5",
    value: ii.value
  }, ii.title))), error && /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
  }, /* @__PURE__ */ React.createElement(ExclamationCircleIcon, {
    className: "h-5 w-5 text-red-500",
    "aria-hidden": "true"
  }))), error && /* @__PURE__ */ React.createElement("p", {
    className: "mt-1 text-sm text-red-600",
    id: "email-error"
  }, error));
};
export default SettingsInput;
