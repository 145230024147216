import React, {useState, useEffect, Fragment} from "../../../_snowpack/pkg/react.js";
import {Dialog, Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import {XIcon} from "../../../_snowpack/pkg/@heroicons/react/outline.js";
import {EyeIcon} from "../../../_snowpack/pkg/@heroicons/react/outline.js";
import axios from "../../../_snowpack/pkg/axios.js";
import {DragDropContext, Droppable, Draggable} from "../../../_snowpack/pkg/react-beautiful-dnd.js";
import SettingsInput from "./components/settings-input/index.js";
import SettingsListItem from "./components/settings-listItem/index.js";
const Settings = ({user, isOpen, setIsOpen, handleLogout, setCounter}) => {
  const [activeInput, setActiveInput] = useState(null);
  const [currentPass, setCurrentPass] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newKeepaApiKey, setNewKeepaApiKey] = useState("");
  const [currentKeepaApiKey, setCurrentKeepaApiKey] = useState(null);
  const [keepaApiKeyError, setKeepaApiKeyError] = useState(null);
  const [preferredRoi, setPreferredRoi] = useState(null);
  const [shippingCost, setShippingCost] = useState(null);
  const [preferredPrice, setPreferredPrice] = useState("buyBox");
  const [profitCalculatorError, setProfitCalculatorError] = useState(null);
  const [currentPassError, setCurrentPassError] = useState(null);
  const [showKeepaApiKey, setShowKeepaApiKey] = useState(false);
  const [startDrag, setStartDrag] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [functionalities, setFunctionalities] = useState([]);
  const isNewPasswordSubmitDisabled = newPassword !== confirmPass || !currentPass || !newPassword || !confirmPass;
  const newPasswordError = newPassword !== confirmPass ? "New password and confirmation do not match" : null;
  const newPasswordFields = [
    {
      label: "Current Password",
      name: "currentPass",
      key: "currentPass",
      type: "password",
      value: currentPass,
      required: true,
      error: currentPassError,
      onInput: (e) => setCurrentPass(e.target.value)
    },
    {
      label: "New Password",
      name: "newPassword",
      key: "newPassword",
      type: "password",
      value: newPassword,
      required: true,
      error: newPasswordError,
      onInput: (e) => setNewPassword(e.target.value)
    },
    {
      label: "Confirm New Password",
      name: "confirmNewPass",
      key: "confirmNewPass",
      type: "password",
      value: confirmPass,
      required: true,
      error: newPasswordError,
      onInput: (e) => setConfirmPass(e.target.value)
    }
  ];
  useEffect(() => {
    getStatPanel();
    getProfitCalculatorSetting();
  }, []);
  const getStatPanel = async () => {
    const {data} = await axios.get("/api/v1/settings/statpanel");
    setFunctionalities(data);
    return data;
  };
  const getProfitCalculatorSetting = async () => {
    const {data} = await axios.get("/api/v1/settings/profit-calculator-setting");
    const {preferredRoi: preferredRoi2, shippingCost: shippingCost2, preferredPrice: preferredPrice2} = data;
    setPreferredRoi(preferredRoi2);
    setShippingCost(shippingCost2);
    setPreferredPrice(preferredPrice2);
  };
  const sendPasswordUpdate = async () => {
    const {status, data: message} = await axios.post("/api/v1/settings/password", {
      currentPassword: currentPass,
      newPassword
    }, {
      validateStatus: () => true
    });
    if (status !== 200) {
      return setCurrentPassError(message);
    }
    setIsOpen(false);
    setCurrentPassError(null);
  };
  const sendNewKeepaApiKeyUpdate = async () => {
    const {status, data: message} = await axios.post("/api/v1/settings/keepa-api-key", {
      keepaApiKey: newKeepaApiKey
    }, {
      validateStatus: () => true
    });
    if (status !== 200) {
      return setProfitCalculatorError(message);
    }
    setIsOpen(false);
    setProfitCalculatorError(null);
  };
  const sendUpdatedProfitCalculatorHandler = async () => {
    const {status, data: message} = await axios.post("/api/v1/settings/profit-calculator-setting", {
      preferredRoi,
      shippingCost,
      preferredPrice
    }, {
      validateStatus: () => true
    });
    if (status !== 200) {
      return setKeepaApiKeyError(message);
    }
    setCounter((old) => old + 1);
    setIsOpen(false);
    setKeepaApiKeyError(null);
  };
  const refreshKeepaApiKey = async () => {
    const {data: keepaApiKey} = await axios.get("/api/v1/settings/keepa-api-key");
    setCurrentKeepaApiKey(keepaApiKey);
  };
  const updateKeepaApiKeyHandler = (event) => {
    event.preventDefault();
    sendNewKeepaApiKeyUpdate();
  };
  const updateProfitCalculatorHandler = (event) => {
    event.preventDefault();
    sendUpdatedProfitCalculatorHandler();
  };
  const updatePasswordHandler = (event) => {
    event.preventDefault();
    sendPasswordUpdate();
  };
  const cancelHandler = async () => {
    setKeepaApiKeyError(null);
    setCurrentPassError(null);
    setActiveInput(null);
    setNewKeepaApiKey("");
    setPreferredRoi(preferredRoi);
    setShippingCost(shippingCost);
    setPreferredPrice(preferredPrice);
    setCurrentPass("");
    setNewPassword("");
    setConfirmPass("");
    setFunctionalities(await getStatPanel());
  };
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setActiveInput(null), 500);
    } else {
      refreshKeepaApiKey();
    }
  }, [isOpen]);
  const handleDragEnd = (result) => {
    if (!result.destination)
      return;
    const items = Array.from(functionalities);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFunctionalities(items);
    setStartDrag(false);
  };
  const handleDragStart = (result) => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    setStartDrag(true);
    setSelectedRow(result.source.index);
  };
  const handleSaveFunc = async () => {
    setCounter((old) => old + 1);
    axios.post("/api/v1/settings/statpanel", {
      statPanel: functionalities
    }, {
      validateStatus: () => true
    });
    setIsOpen(false);
  };
  return /* @__PURE__ */ React.createElement(Transition.Root, {
    show: isOpen,
    as: Fragment
  }, /* @__PURE__ */ React.createElement(Dialog, {
    as: "div",
    static: true,
    className: "fixed inset-0 overflow-hidden",
    open: isOpen,
    onClose: setIsOpen
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 overflow-hidden"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-in-out duration-500",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "ease-in-out duration-500",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement(Dialog.Overlay, {
    className: "absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "fixed inset-y-0 left-0 pr-10 max-w-full flex"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "transform transition ease-in-out duration-500 sm:duration-700",
    enterFrom: "-translate-x-full",
    enterTo: "translate-x-0",
    leave: "transform transition ease-in-out duration-500 sm:duration-700",
    leaveFrom: "translate-x-full",
    leaveTo: "-translate-x-full"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-screen max-w-md"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "h-full flex flex-col py-6 bg-white shadow-xl"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-start justify-between"
  }, /* @__PURE__ */ React.createElement(Dialog.Title, {
    className: "text-xl font-bold text-gray-900"
  }, activeInput === null && "Settings", activeInput === "password" && "Set New Password", activeInput === "keepaApiKey" && "Set New Keepa API Key", activeInput === "profitCalculator" && "Profit Calculator Settings", activeInput === "editFunctionality" && "Edit Stat Panels"), /* @__PURE__ */ React.createElement("div", {
    className: "ml-3 h-7 flex items-center"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "bg-white rounded-md text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500",
    onClick: () => setIsOpen(false)
  }, /* @__PURE__ */ React.createElement("span", {
    className: "sr-only"
  }, "Close panel"), /* @__PURE__ */ React.createElement(XIcon, {
    className: "h-6 w-6",
    "aria-hidden": "true"
  })))), /* @__PURE__ */ React.createElement("p", {
    className: `leading-none `,
    style: {fontSize: "10px"}
  }, activeInput === "editFunctionality" && "Long press to enable drag and drop")), /* @__PURE__ */ React.createElement("div", {
    className: "mt-6 relative flex-1 px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "h-full border-t-2 border-gray-200",
    "aria-hidden": "true"
  }, activeInput === null && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "mb-6 border-b-2 py-2"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "py-2"
  }, /* @__PURE__ */ React.createElement("label", {
    className: "text-gray-500 text-sm"
  }, "Name"), /* @__PURE__ */ React.createElement("div", null, user.name)), /* @__PURE__ */ React.createElement("div", {
    className: "py-2"
  }, /* @__PURE__ */ React.createElement("label", {
    className: "text-gray-500 text-sm"
  }, "Email Address"), /* @__PURE__ */ React.createElement("div", null, user.email)), currentKeepaApiKey !== null && /* @__PURE__ */ React.createElement("div", {
    className: "py-2"
  }, /* @__PURE__ */ React.createElement("label", {
    className: "text-gray-500 text-sm"
  }, "Keepa API Key"), /* @__PURE__ */ React.createElement("div", {
    className: "flex gap-2"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "break-all tracking-tight"
  }, showKeepaApiKey ? currentKeepaApiKey : currentKeepaApiKey.replace(/./g, "*")), /* @__PURE__ */ React.createElement("button", {
    onClick: () => setShowKeepaApiKey((old) => !old)
  }, /* @__PURE__ */ React.createElement(EyeIcon, {
    className: `h-6 w-6 ${showKeepaApiKey ? "text-primary" : "text-gray-600"}`
  }))))), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col text-primary text-lg font-medium gap-4 text-left pb-6 border-b-2"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "text-left",
    onClick: () => setActiveInput("password")
  }, "Change Password"), /* @__PURE__ */ React.createElement("button", {
    className: "text-left",
    onClick: () => setActiveInput("keepaApiKey")
  }, "Change Keepa API Key"), /* @__PURE__ */ React.createElement("button", {
    className: "text-left",
    onClick: () => setActiveInput("profitCalculator")
  }, "Profit Calculator Settings"), /* @__PURE__ */ React.createElement("button", {
    className: "text-left",
    onClick: () => setActiveInput("editFunctionality")
  }, "Edit Stat Panels")), /* @__PURE__ */ React.createElement("div", {
    className: "text-primary text-lg font-medium text-left mt-4"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "text-left",
    onClick: handleLogout
  }, "Logout"))), activeInput === "password" && /* @__PURE__ */ React.createElement("form", {
    className: "space-y-2 mt-4",
    onSubmit: updatePasswordHandler
  }, newPasswordFields.map((props) => /* @__PURE__ */ React.createElement(SettingsInput, {
    ...props
  })), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between gap-2 pt-4"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "p-2 bg-gray-100 rounded border border-gray-300 flex-1",
    onClick: cancelHandler
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "p-2 bg-primary text-white rounded flex-1 disabled:opacity-50 disabled:cursor-not-allowed",
    disabled: isNewPasswordSubmitDisabled
  }, "Update"))), activeInput === "keepaApiKey" && /* @__PURE__ */ React.createElement("form", {
    className: "space-y-2 mt-4",
    onSubmit: updateKeepaApiKeyHandler
  }, /* @__PURE__ */ React.createElement(SettingsInput, {
    label: "New Keepa API Key",
    name: "newKeepaApiKey",
    value: newKeepaApiKey,
    type: "textarea",
    onInput: (e) => setNewKeepaApiKey(e.target.value),
    error: keepaApiKeyError,
    rows: 3,
    required: true
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between gap-2 pt-4"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "p-2 bg-gray-100 rounded border border-gray-300 flex-1",
    onClick: cancelHandler
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "p-2 bg-primary text-white rounded flex-1 disabled:opacity-50 disabled:cursor-not-allowed",
    disabled: !newKeepaApiKey
  }, "Update"))), activeInput === "profitCalculator" && /* @__PURE__ */ React.createElement("form", {
    className: "space-y-2 mt-4",
    onSubmit: updateProfitCalculatorHandler
  }, /* @__PURE__ */ React.createElement(SettingsInput, {
    label: "Minimum ROI",
    name: "preferredRoi",
    value: preferredRoi,
    type: "text",
    onInput: (e) => setPreferredRoi(e.target.value),
    error: profitCalculatorError,
    required: true
  }), /* @__PURE__ */ React.createElement(SettingsInput, {
    label: "Inbound Shipping Cost",
    name: "shippingCost",
    value: shippingCost,
    type: "text",
    onInput: (e) => setShippingCost(e.target.value),
    error: profitCalculatorError,
    required: true
  }), /* @__PURE__ */ React.createElement(SettingsInput, {
    label: "Preferred Base Price",
    name: "preferredPrice",
    options: [
      {title: "Buy Box", value: "buyBox"},
      {title: "New", value: "new"},
      {title: "Used", value: "used"},
      {title: "New FBA", value: "newFba"},
      {title: "New FBM", value: "newFbm"}
    ],
    value: preferredPrice || "buyBox",
    type: "select",
    onInput: (e) => setPreferredPrice(e.target.value),
    error: profitCalculatorError
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between gap-2 pt-4"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "p-2 bg-gray-100 rounded border border-gray-300 flex-1",
    onClick: cancelHandler
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "p-2 bg-primary text-white rounded flex-1 disabled:opacity-50 disabled:cursor-not-allowed",
    disabled: !preferredRoi
  }, "Save"))), activeInput === "editFunctionality" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(DragDropContext, {
    onDragEnd: handleDragEnd,
    onDragStart: handleDragStart
  }, /* @__PURE__ */ React.createElement(Droppable, {
    droppableId: "functionalities"
  }, (provided) => /* @__PURE__ */ React.createElement("ul", {
    className: "space-y-2 mt-4",
    ...provided.droppableProps,
    ref: provided.innerRef
  }, functionalities.map((func, index) => /* @__PURE__ */ React.createElement(Draggable, {
    key: func.title,
    draggableId: func.title,
    index
  }, (provided2) => /* @__PURE__ */ React.createElement(SettingsListItem, {
    provided: provided2,
    func,
    index,
    setFunctionalities,
    startDrag,
    selectedRow
  }))), provided.placeholder))), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between gap-2 pt-4"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "p-2 bg-gray-100 rounded border border-gray-300 flex-1",
    onClick: cancelHandler
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "p-2 bg-primary text-white rounded flex-1",
    onClick: handleSaveFunc
  }, "Save")))))))))))));
};
export default Settings;
